import React, { useState } from 'react'

export default function ErrorComponent(): ReturnType<React.FC> {

    const [triggerError, setTriggerError] = useState(false);

    if (triggerError) {
        // Simulate a runtime error
        throw new Error("This is a deliberate error!");
    }

    return (
        <div>
            <h1>No errors yet.</h1>
            <button onClick={() => setTriggerError(true)}>Trigger Error</button>
        </div>
    );

}
