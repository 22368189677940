import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { DashboardLayoutProps } from '../../interfaces/componentsInterface/layout'
import NavControls from "../NavControls";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getRooms } from "../../services/room";
import { roomSelector } from "../../features/room/roomSlice";
import useInterval from "../../hooks/useInterval";
import routes from "../../constants/routes";
export default function DashboardLayout({ children }: DashboardLayoutProps): ReturnType<React.FC> {
    const [searchValue, setSearchValue] = useState<string>('');
    const dispatch = useAppDispatch();


    const roomsData = useAppSelector(roomSelector);


    useInterval(() => {
        if (window.location.pathname === routes.dashboard) {

            dispatch(getRooms());
        }
    }, 5000)


    const filteredRooms = roomsData?.rooms.filter((room) => {
        return room?.roomName?.toLowerCase().includes(searchValue.toLowerCase())
    });

    const additionalProps = { filteredRooms: filteredRooms };


    return (

        <>
            <div className="w-screen h-screen p-6 bg-stone-100 flex items-start gap-6">
                <Sidebar />
                <div className="w-[80%] flex flex-col justify-start items-start gap-6">
                    <NavControls searchValue={searchValue} setSearchValue={setSearchValue} />
                    {React.Children.map(children, child => {

                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, { ...additionalProps });
                        }
                        return child;
                    })}
                </div>


            </div>


        </>
    )
}