import config from "./config";

export default async function serviceWorker() {
  if (!config.pwa.enabled) return;
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      navigator.serviceWorker.register("/sw.js").then(
        function (registration) {
          // Registration was successful
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        },
        function (err) {
          // registration failed :(
          console.log("ServiceWorker registration failed: ", err);
        }
      );
    });
    if (navigator.serviceWorker.controller) {
      // update pwa configuration as it doesn't have access to it
      // navigator.serviceWorker.controller.postMessage({
      //   key: "cacheModels",
      //   val: config.pwa.cacheModels,
      // });
      // navigator.serviceWorker.controller.postMessage({
      //   key: "cacheWASM",
      //   val: config.pwa.cacheWASM,
      // });
      // navigator.serviceWorker.controller.postMessage({
      //   key: "cacheOther",
      //   val: config.pwa.cacheOther,
      // });

      console.log("pwa active:", navigator.serviceWorker.controller.scriptURL);
      const cache = await caches.open(config.pwa.cacheName);
      if (cache) {
        const content = await cache.matchAll();
        console.log("pwa cache:", content.length, "files");
      }
    }
  } else {
    console.log("cannot register service worker");
  }
}
