import { createAsyncThunk } from "@reduxjs/toolkit";
import { authRequest } from ".";
import { getToken } from "../utils/Token";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "",
  },
};

export const uploadFeedbackImage =   async (body: any) => {
    config["headers"]["Authorization"] = getToken();


    
    const data = new FormData();
    data.append("file", body.file);
    // data.append("parentUsername", body.parentUsername);
    // data.append("roomname", body.room);
    // data.append("agentUsername", body.agentUsername);
    data.append("parentRole", body.parentRole);
    data.append("roomId", body.roomId);
    data.append("parentUserId", body.parentUserId);
    data.append("peerUserId", body.peerUserId);
    data.append("comment", body.comment);

    const response = await authRequest.post(
      "/v1/api/feedback/add",
      data,
      config
    );
    return response.data;
  }

