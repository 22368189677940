export type SegmentationModel = "meet";
export type SegmentationBackend = "wasmSimd";
export type InputResolution = "256x144";

export const inputResolutions: {
  [resolution in InputResolution]: [number, number];
} = {
  "256x144": [256, 144],
};

export type PipelineName = "canvas2dCpu";

export type SegmentationConfig = {
  model: SegmentationModel;
  backend: SegmentationBackend;
  inputResolution: InputResolution;
  pipeline: PipelineName;
};

export function getTFLiteModelFileName(
  model: SegmentationModel,
  inputResolution: InputResolution
) {
  return "segm_full_v679";
}
