import { ArrowTopRightOnSquareIcon, MagnifyingGlassIcon, XMarkIcon, ClockIcon, BellAlertIcon } from "@heroicons/react/24/outline"
import { AlertProps } from "../../interfaces/componentsInterface/alerts"
import { useEffect, useRef, useState } from "react";
import routes from "../../constants/routes";
import SkeletonLoading from "../SkeletonLoading";
import { useAppSelector } from "../../app/hooks";
import { alertsSelector } from "../../features/alerts/alertsSlice";
import moment from "moment";
import { timeZoneFormat } from "../../utils/datetimeformat";
import formatDuration from "../../utils/formatDuration";
import { alertValues } from "../../constants/alertValues";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "../../app/store";
import { EventType } from "../../interfaces/slicesInterface/alerts";


export default function Alerts({ active, setActive, setIsAlertActive, setIsParticipantActive, isAlertActive, inPopup }: AlertProps): ReturnType<React.FC> {

    const [searchValue, setSearchValue] = useState<string>('');
    let alertsPopup = useRef<Window | null>(null);
    const [isAlertsPopupOpenend, setIsAlertsPopupOpenend] = useState(false);
    const alerts = useAppSelector(alertsSelector)
    const { format } = timeZoneFormat();
    const handleAlertsClose = () => {
        setActive && setActive(false);
        setIsAlertActive && setIsAlertActive({ val: false, count: 0 });
        setIsParticipantActive && setIsParticipantActive({ val: false, count: 0 });
    }



    var alertsWindow: any;


    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
          if (event.data?.action === "closeAlertsPopup") {
            if (alertsPopup.current) {
              alertsPopup.current.close();
              setIsAlertsPopupOpenend(false);
            }
          }
        }
    
        window.addEventListener("message", handleMessage);
    
        return () => {
          window.removeEventListener("message", handleMessage);
        };
      }, []);

    const openAlertsPopup = () => {
        let compiledTailwindCssUrl = `${process.env.REACT_APP_CDN_BASE_URL}/tailwind/tailwind.output.css`;
        if (!isAlertsPopupOpenend) {
            setIsAlertsPopupOpenend(true);

            localStorage.setItem("alertsPopout", "true");
            // alertsPopup.current = window.open("", "_blank", "width=500,height=650");
            // Calculate the top and left positions based on the current screen's available width and height
            const topPosition = window.screenY + (window.innerHeight * 0.15); 
            const leftPosition = window.screenX + (window.innerWidth * 0.4); 

            // Open popup with responsive width, height, and calculated top/left positions
            alertsPopup.current = window.open("", "_blank", `width=500,height=650,top=${topPosition},left=${leftPosition}`);

            if (!alertsPopup.current) {
                console.error("Failed to open Alerts popup window.");
                return;
            }

            alertsPopup.current.document.open("text/html", "replace");

            alertsPopup.current.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <title>Alerts - Collaborationroom.ai</title>
                    <link rel="stylesheet" href="${compiledTailwindCssUrl}">
                    <style>
                        * {
                            margin: 0;
                            padding: 0;
                            box-sizing: border-box;
                            scroll-behavior: smooth;
                            /* overflow: hidden; */
                        }
            
                        ::selection {
                            background-color: #4EA3DB;
                            color: #fff;
                        }
            
                        .scrollbar::-webkit-scrollbar {
                            width: 10px;
                        }
            
                        .scrollbar::-webkit-scrollbar-thumb {
                            background-color: #eaeaea;
                            border-radius: 10px;
                        }
            
                        .scrollbar::-webkit-scrollbar-thumb:hover {
                            background: #ccc;
                        }
            
                        textarea::placeholder {
                            text-align: center;
                            padding: auto 0 !important;
                            display: table-cell;
                            vertical-align: middle !important;
                            margin-top: 10% !important;
                        }
            
                        textarea:focus {
                            outline: none;
                            max-height: 100%;
                            max-width: 100% !important;
                            overflow-y: scroll !important;
                            overflow-x: hidden;
                        }
            
                        .w-full__suggestions {
                            margin: 0 !important;
                            z-index: 1000;
                            margin-left: 2px;
                            top: 10% !important;
                            left: 10% !important;
                        }
            
                        .w-full__suggestions__list {
                            z-index: 10;
                            background: white;
                            border: solid 1px #4EA3DB;
                            background: #4EA3DB;
                            color: #fff;
                        }
            
                        .w-full__highlighter {
                            height: auto !important;
                        }
            
                        textarea::-webkit-scrollbar {
                            width: 10px;
                        }
            
                        textarea::-webkit-scrollbar-thumb {
                            background-color: #eaeaea;
                            border-radius: 10px;
                        }
            
                        textarea::-webkit-scrollbar-thumb:hover {
                            background: #ccc;
                        }
            
                        :root {
                            --blue: #4EA3DB;
                        }
                    </style>
                    <script>
                        window.addEventListener('storage', (event) => {
                            if (event.key === 'alertsPopout') {
                                const isAlertPopOut = JSON.parse(event.newValue); // Parse the new value from storage
                                if (!isAlertPopOut) {
                                    window.close();
                                }
                            }
                        });
            
                        window.addEventListener('beforeunload', () => {
                            if (window.opener) {
                                window.opener.postMessage({ action: "closeAlertsPopup" }, "*");
                            }
                        });
                    </script>
                </head>
                <body>
                    <div id="root"></div>
                </body>
                </html>
            `);
            

            alertsPopup.current.document.close();

            alertsPopup.current.onload = () => {
                const rootElement = alertsPopup.current?.document.getElementById("root");

                if (rootElement) {
                    const root = createRoot(rootElement);
                    root.render(
                        <Provider store={store}>
                            <div>
                                <Alerts
                                    active={active}
                                    setActive={setActive}
                                    setIsAlertActive={setIsAlertActive}
                                    setIsParticipantActive={setIsParticipantActive}
                                    isAlertActive={isAlertActive}
                                    inPopup={true}
                                />
                            </div>

                        </Provider>
                    );
                } else {
                    console.error("Root element not found in the chat popup window.");
                }
            };




            const closeAlertsPopup = () => {
                if (alertsPopup.current) {
                  alertsPopup.current.close();
                  setIsAlertsPopupOpenend(false);
                }
              };
        
              // Add event listener for beforeunload to close the popup on page reload or navigation
              window.addEventListener("beforeunload", closeAlertsPopup);
        
              // Ensure the event listener is removed when the component is unmounted or popup is closed
              alertsPopup.current.onbeforeunload = () => {
                setIsAlertsPopupOpenend(false);
                window.removeEventListener("beforeunload", closeAlertsPopup);
              };

        }


    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const filteredAlerts = Object.entries(alerts?.alerts).filter(([key, details]) => {
        return details?.peerName?.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            details?.peerUsername?.toLowerCase().startsWith(searchValue.toLowerCase());
    });

    useEffect(() => {
        return () => {
            if (alertsWindow && !alertsWindow.closed) {
                alertsWindow.close();
            }
        };
    }, [alertsWindow]);

    useEffect(() => {
        console.log("HEHE", { isAlertsPopupOpenend })
    }, [isAlertsPopupOpenend])

    return (
        <>

            <div className={`w-full  ${active ? " h-[49%]" : "h-full"} bg-white ${inPopup && "h-screen overflow-hidden"}  rounded-2xl sm:w-full transition-all shadow-md duration-500   ${isAlertActive?.val ? "flex" : "hidden"} justify-start items-start flex-col gap-2 `}>
                <div className={` w-full flex flex-col justify-start items-start p-2 ${inPopup ? "h-[10%]" : "h-[25%]"} `} >
                    <div className=" w-full h-2/4 flex justify-between items-center ">
                        <h1 title="Alerts" className=" font-sans text-gray-900 text-base font-semibold" >Alerts</h1>

                        {
                            !inPopup &&
                            <div className=" flex justify-center items-center gap-1">

                                <ArrowTopRightOnSquareIcon className={`xl:h-5 xl:w-5  lg:h-4 lg:w-4 sm:h-4 sm-h-4  text-base ${isAlertsPopupOpenend ? "text-blue hover:text-blue" : "text-gray-500 hover:text-gray-900"}   hover:cursor-pointer transition-colors `} onClick={openAlertsPopup} title="Popout Alerts" />

                                <XMarkIcon className="xl:h-5 xl:w-5  lg:h-4 lg:w-4 sm:h-4 sm-h-4  text-base text-gray-500 hover:text-gray-900 hover:cursor-pointer transition-colors " onClick={handleAlertsClose} />
                            </div>
                        }
                    </div>

                    <div className="relative rounded-md shadow-sm w-full mt-1 ">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                        <input
                            type="text"
                            name="text"
                            id="text"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-00 sm:text-sm sm:leading-6 focus:outline-none"
                            placeholder="Search Name, Username.."
                            value={searchValue}
                            onChange={handleSearchChange}

                        />
                    </div>

                </div>


                <div className={`w-full h-screen overflow-y-scroll scrollbar flex flex-col justify-start items-start p-1 gap-2 `}>

                    {

                        filteredAlerts.map(([key, details], index) => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const [userId, event, startTime] = key.split("_");
                            return (
                                event === "BRB" || event === "Lunch" || event === "Break" ?
                                    <div key={key} className="flex flex-col justify-between items-start w-full text-sm font-sans p-2  rounded-lg border-[.5px] border-gray-300 cursor-default hover:shadow-md">
                                        <div className=" w-full flex justify-start items-start gap-2">


                                            <BellAlertIcon className="h-5 w-5  text-base text-blue  hover:cursor-pointer transition-colors" />
                                            <p className=" text-sm font-semibold truncate" title={`${details.peerName}(${details.peerUsername})`}>{details.peerName}</p>


                                        </div>
                                        <div className="w-full flex justify-start items-start flex-col">
                                            <p className="text-blue  text-sm font-semibold ">{alertValues[event]}</p>
                                            <p className=" text-xs"><span className="font-semibold">Start : </span>{moment(details.startTime).format(format)}</p>
                                            <p className=" text-xs"><span className="font-semibold">End : </span>{details.endTime ? moment(details.endTime).format(format) : ""}</p>
                                            <p className="text-xs font-semibold text-gray-500">{details.endTime ? `Duration: ${formatDuration(Number(details.endTime) - Number(details.startTime))}` : ""}</p>
                                        </div>
                                    </div>

                                    :



                                    event === "FaceDetection" ? <div key={key} className="flex flex-col justify-between items-start w-full text-sm font-sans p-2  rounded-lg border-[.5px] border-gray-300 cursor-default hover:shadow-md">
                                        <div className=" w-full flex justify-start items-center gap-2">
                                            <BellAlertIcon className="h-5 w-5  text-base text-blue  hover:cursor-pointer transition-colors" />
                                            <p className=" text-sm font-semibold truncate" title={`${details.peerName}(${details.peerUsername})`}>{details.peerName}</p>
                                        </div>
                                        <div className="w-full flex justify-start items-start flex-col">
                                            <p className="text-blue  text-sm font-semibold ">Multiple Faces Detected</p>
                                            <p className=" text-sm"><span className="font-semibold">At: </span>{moment(details.timestamp).format(format)}</p>
                                            <p className=" text-sm break-all">{details.message}</p>

                                        </div>
                                    </div> :
                                        <div key={key} className="flex flex-col justify-between items-start w-full text-sm font-sans p-2  rounded-lg border-[.5px] border-gray-300 cursor-default hover:shadow-md">
                                            <div className=" w-full flex justify-start items-center gap-2">
                                                <BellAlertIcon className="h-5 w-5  text-base text-blue  hover:cursor-pointer transition-colors" />
                                                <p className=" text-sm font-semibold truncate" title={`${details.peerName}(${details.peerUsername})`}>{details.peerName}</p>
                                            </div>
                                            <div className="w-full flex justify-start items-start flex-col">
                                                <p className="text-blue  text-sm font-semibold ">{alertValues[event]}</p>
                                                <p className=" text-sm"><span className="font-semibold">At: </span>{moment(details.timestamp).format(format)}</p>

                                            </div>
                                        </div>

                            )
                        })
                    }













                </div>

            </div>

        </>
    )
}