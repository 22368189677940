import { Component, ErrorInfo, ReactNode } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react'
import Button from '../Button';
import { Link } from 'react-router-dom';
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
    errorMessage: ""

  };

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {

      return <ErrorBoundaryUI error={this.state.errorMessage} />

    }

    return this.props.children;
  }
}

export default ErrorBoundary;




const ErrorBoundaryUI = ({error}:{error:string}) => {
  return <div className=' w-screen h-screen flex flex-col justify-center items-center bg-gray-50'>
    <div className='w-[30%] h-[35%] bg-white rounded-2xl shadow-md flex flex-col justify-center items-center'>
      <p><ExclamationCircleIcon className='h-20 w-20 text-red-600 bg-red-50 rounded-full' /></p>
      <p className=' text-red-600 text-lg mt-2 mb-2'>Sorry, There was an error.</p>
      <p className=' text-gray-500 mt-2 mb-2'>{error}</p>
      <div className='flex justify-center items-center mt-2 '>
        <Link to="/dashboard"><Button type='outline'>Back to Main Menu</Button></Link>
        <Button type='primary' className=' ml-3' onClick={() => {
          window.location.reload();
        }}>Reload Page</Button>
      </div>
    </div>
  </div>
}