/* eslint-disable jsx-a11y/no-redundant-roles */
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import Logo from '../../assets/images/logo_full.png';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import {

    FolderIcon,
    HomeIcon,
    UserIcon,
    KeyIcon,
    UserCircleIcon,
    InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
// import { logOutActiveTime, logoutUser } from '../../services/login'
import routes from '../../constants/routes';
import { userDetailsSelector } from '../../features/userDetails/userDetailsSlice';
import { adminVerificationSelector } from '../../features/adminVerification/adminVerification';
// import { setPopupOpenedFrom } from '../../features/chat/chatSlice';
import { environmentSelector } from '../../features/environment/environmentSlice';
import { constantMethod } from '../../utils/constantMethod';
import { personalizationSelector } from '../../features/personalization/personalization';
import { generateThumbnailsUrl } from '../../utils/generateImageURL';
import { getInitials } from '../../utils/initials';
import SocketService from "../../services/socket";
import { socketConnectiontype } from '../../utils/constant';
import { resetChatListing, resetOnlineUsers } from '../../features/chat/chatSlice';
import { useDispatch } from 'react-redux';
const socketInstanceChat = new SocketService(
  socketConnectiontype.chat
).getInstance(socketConnectiontype.chat);

const socketInstanceAC = new SocketService(socketConnectiontype.activityChannel).getInstance(
  socketConnectiontype.activityChannel
);


function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}
export default function Sidebar(): ReturnType<React.FC> {
    const location = useLocation();
    let [isOpen, setIsOpen] = useState(false)
    const user = useAppSelector(userDetailsSelector)
    const adminVerification = useAppSelector(adminVerificationSelector);
    const environments = useAppSelector(environmentSelector);
    const personalization = useAppSelector(personalizationSelector);
    const webLinkStatus = environments?.environments && environments?.environments?.WEBLINK?.status;
    const webLinkAddress = environments?.environments && environments?.environments?.WEBLINK?.value;
    const imageUrl = environments?.environments && environments?.environments?.APP_LOGO?.value;
    const spacesConfig = environments.environments && environments.environments.REACT_APP_SPACES_CONFIG ? environments.environments.REACT_APP_SPACES_CONFIG.data[0] : '';
    const [imageError, setImageError] = useState(false);
    const dispatch = useDispatch();
    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }


    interface NavItem {
        name: string;
        href?: string;
        icon: any;
        current: boolean;
        type?: string;
        children?: NavItem[];
    }

    let navigation: NavItem[] = [
        { name: 'Dashboard', href: routes.dashboard, icon: HomeIcon, current: location.pathname === routes.dashboard },
        {
            name: 'Change Password',
            href: routes.passwordChange,
            icon: KeyIcon,
            current: false,
        },
        {
            name: 'User Personalization',
            icon: UserIcon,
            current: location.pathname === routes.personalization,
            href: routes.personalization,
        },
        // Uncomment if needed
        // {
        //     name: 'Face Validation',
        //     icon: FolderIcon,
        //     current: false,
        //     type: 'face',
        //     children: [
        //         { name: 'Face Registration', href: routes.faceRegistration, current: location.pathname === routes.faceRegistration },
        //         { name: "Admin's Verification", href: routes.adminverification, current: location.pathname === routes.adminverification },
        //     ],
        // },
        { name: 'Face Registration', icon: FolderIcon, href: routes.faceRegistration, current: location.pathname === routes.faceRegistration },
        { name: "Admin's Verification", icon: FolderIcon, href: routes.adminverification, current: location.pathname === routes.adminverification },
        ...(webLinkStatus && constantMethod.checkHigherRole(user.role) ? [{
            name: 'Admin Login',
            href: `${webLinkAddress}`,
            icon: UserCircleIcon,
            current: false
        }] : []),
        { name: 'About', href: '#', icon: InformationCircleIcon, current: false, type: "current" },
    ];


    if (user.role === 'Agent') {
        navigation = navigation
            .filter(navItem => navItem.name !== "Admin's Verification")
    }

    if (constantMethod.checkHigherRole(user.role)) {
        navigation = navigation
            .filter(navItem => navItem.name !== "Face Registration")

    }



    // const dispatch = useAppDispatch();


    // const handleLogout = () => {
    //     dispatch(logOutActiveTime())
    //     dispatch(logoutUser());
    //     localStorage.removeItem("ACPopOut");
    //     localStorage.removeItem("chatPopOut");
    //     localStorage.removeItem("participantsPopOut");
    //     localStorage.removeItem("alertsPopout");
    //     dispatch(setPopupOpenedFrom(""))
    //     localStorage.removeItem('isGeoLocationExported');
    //     localStorage.removeItem('isSamlLogin');
    // }


    const handleNavButtonClick = (component: string) => {
        if (component === 'About') {
            openModal()
        }
    }

    const clearOnUnmountSidebar = async () => {

        try {

            const chatPromise = new Promise((resolve, reject) => {
                try {

                    if (socketInstanceChat) {
                        socketInstanceChat.sendMessage(
                            "MANUAL_DISCONNECT_PEER",
                            {}
                        );
                    }


                    if (socketInstanceChat?.messageSubscriber) {
                        socketInstanceChat.messageSubscriber.unsubscribe();
                        socketInstanceChat.messageSubscriber = null;
                    }
                    if (socketInstanceChat) {
                        socketInstanceChat.closeSocketConnection(true);
                        socketInstanceChat.reconnectFlushState();
                        socketInstanceChat.socket = undefined;
                        // socketInstanceChat = null;
                        dispatch(resetOnlineUsers());
                        dispatch(resetChatListing());
                        // closeChatsPopup();
                    }

                    resolve("Dashboard Chat Disconnected");
                }
                catch (err: any) {
                    reject(`An error occurred during Desktop Chat disconnection: ${err.message}`);
                }
            })



            const acPromise = new Promise((resolve, reject) => {
                try {
                    if (socketInstanceAC) {
                        socketInstanceAC.sendMessage("MANUAL_DISCONNECT_PEER", {});
                    }

                    if (socketInstanceAC?.messageSubscriber) {
                        socketInstanceAC.messageSubscriber.unsubscribe();
                        socketInstanceAC.messageSubscriber = null;
                    }

                    if (socketInstanceAC) {
                        socketInstanceAC.closeSocketConnection(true);
                        socketInstanceAC.reconnectFlushState();
                        socketInstanceAC.socket = undefined;

                    }
                    resolve("Dashboard AC Disconnected");
                }
                catch (err: any) {
                    reject(`An error occurred during Desktop AC disconnection: ${err.message}`);
                }
            })


            await Promise.all([chatPromise, acPromise]);
            console.log("All promises resolved successfully.");
        } catch (err: any) {
            console.error("One or more promises failed:", err);
        }

    }

    return (
        <>

            <div className="flex flex-col gap-y-5 overflow-y-auto scrollbar  bg-white px-6 w-[20%] h-full  rounded-2xl">
                <Link to='/'>
                    <div className="flex h-16 gap-6 items-center justify-center cursor-pointer">
                        {imageUrl ? (
                            <img
                                className="h-9 mt-5 w-auto"
                                src={imageUrl}
                                alt="Your Company"
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = Logo;
                                }}
                            />
                        ) : (
                            <img
                                className="h-9 mt-5 w-auto"
                                src={Logo}
                                alt="Your Company"
                            />
                        )}
                    </div>
                </Link>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7 ">
                        <li>
                            <ul role="list" onClick={async () => await clearOnUnmountSidebar()} className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        {!item.children ? (

                                            item.type !== 'current' ?

                                                <Link
                                                    to={item.name === "Face Registration" && !user.faceVerification.isFaceVerificationEnabled ? routes.dashboard : item.href as string}
                                                    className={classNames(
                                                        item.current ? 'bg-gray-100' : 'hover:bg-gray-100',
                                                        `group flex gap-x-3 rounded-md p-2 text-[.80rem] leading-6 font-semibold text-gray-700 cursor-pointer relative ${item.name === "Face Registration" && !user.faceVerification.isFaceVerificationEnabled && "cursor-not-allowed hover:bg-white"}`
                                                    )}
                                                >
                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                    {item.name} {item.name === "Admin's Verification" && adminVerification.count > 0 ? <span className='w-2 h-2 rounded-full bg-red-600 absolute right-[0] top-[40%]'></span> : ""}
                                                </Link> :
                                                <button

                                                    onClick={() => handleNavButtonClick(item.name)}
                                                    className={classNames(
                                                        item.current ? 'bg-gray-100' : 'hover:bg-gray-100',
                                                        'group flex gap-x-3 rounded-md p-2 text-[.80rem] leading-6 font-semibold text-gray-700 cursor-pointer w-full'
                                                    )}>
                                                    <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                    {item.name}
                                                </button>


                                        ) : (
                                            <Disclosure as="div">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button
                                                            className={classNames(
                                                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                                'flex items-center w-full relative text-left rounded-md p-2 gap-x-3 text-[.80rem] leading-6 font-semibold text-gray-700'
                                                            )}
                                                        >
                                                            <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                            {item.name} {item.name === "Admin's Verification" && adminVerification.count > 0 ? <span className='w-2 h-2 rounded-full bg-red-600 absolute right-[0]'></span> : ""}
                                                            <ChevronRightIcon
                                                                className={classNames(
                                                                    open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                                    'ml-auto h-5 w-5 shrink-0'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </Disclosure.Button>
                                                        {/* <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                            {item?.children?.map((subItem: any) => (

                                                                <li key={subItem.name} >
                                                                    {/* 44px */}
                                                                    {/* <Link

                                                                        to={subItem.name === "Face Registration" && !user.faceVerification.isFaceVerificationEnabled ? routes.dashboard : subItem.href}
                                                                        className={classNames(
                                                                            subItem.current ? 'bg-gray-100' : 'hover:bg-gray-100',
                                                                            `block rounded-md py-2 pr-2 pl-9 text-[.80rem] leading-6 text-gray-700 relative ${subItem.name === "Face Registration" && !user.faceVerification.isFaceVerificationEnabled && "cursor-not-allowed hover:bg-white"} `
                                                                        )}
                                                                    >
                                                                        {subItem.name} {subItem.name === "Admin's Verification" && adminVerification.count > 0 ? <span className='w-2 h-2 rounded-full bg-red-600 absolute right-[0] top-[40%]'></span> : ""}
                                                                    </Link>
                                                                </li>
                                                            ))} */}
                                                        {/* </Disclosure.Panel> */}
                                                    </>
                                                )}
                                            </Disclosure>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </li>
                        {/* <li className="-mx-6 mt-auto">
                            <p
                                onClick={handleLogout}
                                className="flex items-center gap-x-4 px-6 py-3 mb-3 text-md font-semibold leading-6 text-red-700 hover:bg-gray-50 cursor-pointer"
                            >
                                <ArrowLeftStartOnRectangleIcon className="h-6 w-6 shrink-0 text-red-700" />
                                <span aria-hidden="true">Logout</span>
                            </p>
                        </li> */}
                        <li className='-mx-6 mt-auto mb-2'>
                            <div className="flex justify-center items-center gap-2 w-full">
                                {
                                    personalization.personalization && personalization.personalization.imageUrl && imageError ? <img
                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                        src={personalization.personalization.imageUrl}
                                        alt=""

                                    /> : personalization.personalization && personalization.personalization.imageUrl && !imageError ? <img
                                        className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                        src={generateThumbnailsUrl(personalization.personalization.imageUrl.split('/').pop(), 240, user.custId, user.userId, spacesConfig)}
                                        alt=""
                                        onError={() => setImageError(true)}
                                    /> :

                                        <span className="inline-flex h-9 w-9 items-center justify-center rounded-full bg-blue">
                                            <span className="text-sm font-medium leading-none text-white">{getInitials(`${user.firstName + ' ' + user.lastName}}`)}</span>
                                        </span>

                                }
                                <div className="flex flex-col justify-start items-start w-4/5" >
                                    <p className="w-full font-sans text-sm font-semibold capitalize truncate" title={`${user.firstName} ${user.lastName}(${user.custCode})`} >{`${user.firstName} ${user.lastName}(${user.custCode})`} </p>
                                    <p className="w-full font-sans text-sm text-gray-500 capitalize">{user.role}</p>
                                </div>
                            </div>
                        </li>

                    </ul>
                </nav>
            </div>



            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Current Version
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            The current version of the application is 4.0
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border-[1px] border-blue-50 bg-white  px-4 py-2 text-sm font-medium text-blue hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Got it, thanks!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>


        </>
    )
}