export enum EventType {
    BRB = 'BRB',
    Lunch = 'Lunch',
    Break = 'Break',
    FaceDetection = 'FaceDetection',
    CellphoneDetection = 'CellphoneDetection',
    RoomEnter="RoomEnter",
    RoomLeave="RoomLeave",
    MissedCall="MissedCall",
  }
  export interface EventDetails {
    startTime: string | number;
    endTime?: string | number;
    timestamp?: string | number;
    peerName: string;
    peerUsername: string;
    message?: string
  }  


 export interface Alert {
    userId: string;
    peerUsername: string;
    peerName: string;
    event: EventType;
    startTime: string | number;
    endTime?: string | number;
    timestamp?: string | number;
    message?:string
  }
  
  export interface AlertsState {
    alerts: { [key: string]: EventDetails };
    notificationCounter:number;
  }