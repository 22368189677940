export const alertValues:any = {
  BRB: "Be Right Back",
  Lunch: "Lunch",
  Break: "Break",
  FaceDetection: "Face Detection",
  CellphoneDetection: "Cellphone Detection",
  EmotionDetection: "Emotion Detection",
  RoomEnter:"Joined the Room",
  RoomLeave:"Left the Room",
  MissedCall:"Missed Call",
};
