import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    IChatUser,
    IDraft,
    IMessage,
    IchatInterface,
} from "../../interfaces/slicesInterface/chat";
import { RootState } from "../../app/store";
import { fetchChatListing } from "../../services/chat.service";
import toast from "react-hot-toast";
import { chatType } from "../../utils/constant";

const initialState: IchatInterface = {
     loading: false,
     success: false,
     error: "",
    onlineUsers: [],
    messageList: [],
    newMessageList: [],
    draftMessages: [],
    selectedChatUser: {
        peerSessionId: "",
        peerUserId: "",
        peerName: "",
        peerUsername: "",
        peerType: "",
        emojiForIconPeer: "",
        emojiForHandAndLunchPeer: "",
        isPrivateCallOn: "",
        isCallModalOn: "",
        networkStrength: "",
        time: "",
        userId: "",
        chatType: "",
        selectedRoom: "",
        imageUrl:"",
        selectedRoomId: "",
        selectedRoomBussId: ""
    },
    chatListing: [],
    unseenId:"",
    typeStatus: {active: false, typerName: '', typerUserId: '', typerSocketId: '', chatType: ''},
    chatListingReadStatus: [],
    reconnect: false,
    popupOpenedFrom: "",
    isChatPopupOpenedFromDashboard: false,
    emojiIconMain: "",
    emojiIconOther: "",
    currentEmoji: "",
    eventTime: ""
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setMessageToList: (state, action: PayloadAction<IMessage>) => {
            // for realtime socket messages (single message)
            state.messageList.push(action.payload);
        },
        setNewMessageToList: (state, action: PayloadAction<IMessage>) => {
            // for realtime socket messages (single message)
            state.newMessageList.push(action.payload);
        },
        appendMessageList: (state, action: PayloadAction<IMessage[]>) => {
            // for messages fetched from DB (batch) (lazy loading case)
            state.messageList.push(...action.payload);
        },
        replaceMessageList: (state, action: PayloadAction<IMessage[]>) => {
            // over-rite
            state.messageList = action.payload;
        },
        setOnlineUsers: (state, action: PayloadAction<IChatUser[]>) => {
            state.onlineUsers = action.payload;
        },
        setSelectedChatUser: (state, action: PayloadAction<IChatUser>) => {
            state.selectedChatUser = action.payload;
        },
        setSelectedChatType: (state, action) => {
            state.selectedChatUser.chatType = action.payload;
        },
        setSelectedRoomData: (state, action) => {
            state.selectedChatUser.selectedRoom = action.payload.currentRoom;
            state.selectedChatUser.chatType = action.payload.chatType;
            state.selectedChatUser.selectedRoomId = action.payload.selectedRoomId;
            state.selectedChatUser.selectedRoomBussId = action.payload.bussId;
        },
        setChatPopupOpenedFromDashboard: (state, action) => {
            state.isChatPopupOpenedFromDashboard = action.payload;
        },
        resetChatState: (state) => {
            state.selectedChatUser = initialState.selectedChatUser;
            state.messageList = initialState.messageList;
        },
        resetOnlineUsers: (state) => {
            state.onlineUsers = initialState.onlineUsers;
        },
        resetChatListing: (state) => {
            state.chatListing = initialState.chatListing;
            state.success=initialState.success;
        },
        updateTypingStatus: (state, action) => {
            state.typeStatus = action.payload;
        },
        setEventMain: (state, action) => {
            state.emojiIconMain = action.payload;
        },
        setEventOther: (state, action) => {
            state.emojiIconOther = action.payload;
        },
        setEventTime: (state, action) => {
            state.eventTime = action.payload;
        },
        resetTypingState: (state) => {
            state.typeStatus = initialState.typeStatus;
        },
        setChatReconnectionStatus: (state, action) => {
            state.reconnect = action.payload;
        },
        setPopupOpenedFrom: (state, action) => {
            state.popupOpenedFrom = action.payload;
        },
        setCurrentEmoji: (state, action) => {
            state.currentEmoji = action.payload;
        },
        // updateReadListing: (state, action) => {
        //     state.chatListingReadStatus.push(action.payload);
        // },
        softDeleteMessage: (state, action) => {
            const { _id } = action.payload;
            const index = state.messageList.findIndex(
                (message) => message._id === _id
            );
            if (index !== -1) {
                state.messageList[index].deleted = true;
            }
        },
        softEditMessage: (state, action) => {
            const { _id, message } = action.payload;
            const index = state.messageList.findIndex(
                (message) => message._id === _id
            );
            if (index !== -1) {
                state.messageList[index].edited = true;
                state.messageList[index].message = message;
            }
        },
        seenMessage: (state, action) => {
            state.messageList = state.messageList.map((message) => {
                if (
                    message.receiver?.receiverUserId ===
                    action.payload.partnerUser.userId

                ) {
                    message.seen = true;
                }
                return message;
            });
        },
        draftMessage: (state, action: PayloadAction<IDraft>) => {
            const { partnerUserId, draft } = action.payload;

            // Check if a similar object already exists
            const existingDraftIndex = state.draftMessages.findIndex(
                (item) => item.partnerUserId === partnerUserId
            );

            // If draft is an empty string, remove the object
            if (draft === "") {
                if (existingDraftIndex !== -1) {
                    state.draftMessages.splice(existingDraftIndex, 1);
                }
            } else {
                // If no similar object exists, push the new one
                if (existingDraftIndex === -1) {
                    state.draftMessages.push(action.payload);
                } else {
                    // If a similar object exists, update it
                    state.draftMessages[existingDraftIndex] = action.payload;
                }
            }

            //TODO Update draft messages on the backend side
        },
        setUnseenId: (state, action: PayloadAction<IMessage[]>) => {
            const unseenMessage = action.payload.filter((message: any) => !message.seen)[0];
            state.unseenId = unseenMessage ? unseenMessage._id : "";
        },
        clearUnseenId: (state) => {
            state.unseenId = "";
        }

        // more reducers here as needed
    },

    extraReducers: (builder) => {
        builder.addCase(fetchChatListing.pending, (state) => {
            if(!state.success){
                state.loading = true;
            }
          });
        builder
            .addCase(
                fetchChatListing.fulfilled,
                (state, action: PayloadAction<any>) => {
                    if (action.payload.success) {
                        state.loading = false;
                        state.chatListing = action.payload.data;
                        state.success = true;
                        // Assign socket Ids to offline users IF they are now Online
                        state.chatListing.forEach((chat) => {
                            const matchedUser = state.onlineUsers.find(
                                (user) => user.userId === chat.userId
                            );
                            if (matchedUser) {
                                chat.peerSessionId = matchedUser.peerSessionId; // selected user is not updated yet it this stage

                                // If user is selected & redux is updated THEN updating session socket id in realtime
                                if (state.selectedChatUser.userId === matchedUser.userId && state.selectedChatUser.chatType === chatType.PRIVATE) {
                                    state.selectedChatUser.peerSessionId = matchedUser.peerSessionId;
                                }
                            }
                        });
                    } else {
                        toast.error("Error fetching chat history");
                        state.loading = false;
                        state.success = false;
                    }
                }
            )
            .addCase(fetchChatListing.rejected, (state, action) => {
                toast.error("Unable to fetch chat history");
            });
    },
});

export const chatSelector = (state: RootState) => state.chat;
export const {
    setMessageToList,
    appendMessageList,
    replaceMessageList,
    setOnlineUsers,
    setSelectedChatUser,
    softDeleteMessage,
    softEditMessage,
    seenMessage,
    draftMessage,
    clearUnseenId,
    setUnseenId,
    setNewMessageToList,
    setSelectedChatType,
    resetChatState,
    setSelectedRoomData,
    resetOnlineUsers,
    resetChatListing,
    updateTypingStatus,
    resetTypingState,
    setPopupOpenedFrom,
    setChatPopupOpenedFromDashboard,
    setChatReconnectionStatus,
    setEventMain,
    setEventOther,
    setEventTime,
    setCurrentEmoji

} = chatSlice.actions;
export default chatSlice.reducer;
