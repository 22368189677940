import SocketService from "../services/socket";
import { RtpCapabilities, RtpParameters } from "mediasoup-client/lib/types";
import {
  mediaTypeConstant,
  resolutionConstant,
  roomViewModeConst,
  socketConnectiontype,
} from "../utils/constant";
import { Device, types as mediasoupTypes } from "mediasoup-client";
import { store } from "../app/store";
import { processIPfromWebrtc } from "./webrtcipaddress";

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

class MediasoupService {
  device: mediasoupTypes.Device | undefined;
  publishTransport: mediasoupTypes.Transport | undefined;
  subscribeTransport: mediasoupTypes.Transport | undefined;
  audioProducer: mediasoupTypes.Producer | undefined;
  screenProducer: mediasoupTypes.Producer | undefined;
  screenTwoProducer: mediasoupTypes.Producer | undefined;
  screenAudioProducer: mediasoupTypes.Producer | undefined;
  audioFileProducer: mediasoupTypes.Producer | undefined;
  videoProducer: mediasoupTypes.Producer | undefined | null;



  // addErrorMessage = (message: string) => {
  //   this.setState({ errorMessages: this.state.errorMessages.concat(message) });
  // };
  loadDevice = async (
    routerRtpCapabilities: mediasoupTypes.RtpCapabilities
  ): Promise<void> => {
    try {
      console.log("JDS999 In TRY")
      this.device = new Device();
    } catch (error: any) {
      console.log('JDS999 Err white creating device: ', error)
      if (error.name === "UnsupportedError") {
        const message = "Browser not supported";
        console.error(message);
        // this.addErrorMessage(message);
      }
    }
    if (this.device && !this.device.loaded) {
      await this.device.load({ routerRtpCapabilities });
    }
  };
  getRouterRtpCaps = async () => {
    try {
      let roomReducer = store.getState().room;
      let roomId = roomReducer.currentRoomId;
      let roomName = roomReducer.currentRoom;
      const data = (await socketInstance.sendMessage(
        "GET_ROUTER_RTP_CAPABILITIES",
        {
          roomname: roomName,
          roomId,
        }
      )) as RtpCapabilities;
      console.log('JDS999: ', data)
      await this.loadDevice(data);
    } catch (error) {
      console.error(error);
    }
  };

  createPublishingTransport = async (roomSocket: SocketIOClient.Socket) => {
    let {
      currentRoomId: roomId,
      currentRoom: roomName,
      viewMode,
    } = store.getState().room;

    if (this.publishTransport) {
      this.publishTransport.close();
    }
    if (this.device && !this.device.loaded) {
      await this.getRouterRtpCaps();
    }
    let publishTransportData: any;
    try {
      publishTransportData = (await socketInstance.sendMessage(
        "CREATE_PRODUCER_TRANSPORT",
        {
          forceTcp: false,
          rtpCapabilities: this.device?.rtpCapabilities,
          roomname: roomName,
          roomId,
        }
      )) as mediasoupTypes.TransportOptions;
      publishTransportData.iceServers = [];
      publishTransportData.iceServers.push({
        urls: "stun:stun.l.google.com:19302",
      });
      if (publishTransportData.turnIps) {
        publishTransportData.turnIps.forEach((turnIp: string) => {
          publishTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=tcp`,
            credential: publishTransportData.turnPassword,
            username: publishTransportData.turnUserId,
          });
          publishTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=udp`,
            credential: publishTransportData.turnPassword,
            username: publishTransportData.turnUserId,
          });
        });
      }
      //SM for testing
      //publishTransportData.iceTransportPolicy = "relay";
    } catch (error: any) {
      console.error(error);
      // this.addErrorMessage(error.message);
    }

    this.publishTransport =
      this.device?.createSendTransport(publishTransportData);

    this.publishTransport?.on(
      "connect",
      async (
        { dtlsParameters }: mediasoupTypes.TransportOptions,
        callback: () => void,
        errback: (err: Error) => void
      ) => {
        try {
          await socketInstance.sendMessage("CONNECT_PRODUCER_TRANSPORT", {
            dtlsParameters,
            roomname: roomName,
            roomId,
          });
          callback();
        } catch (error: any) {
          errback(error);
        }
      }
    );

    this.publishTransport?.on(
      "produce",
      async (
        {
          kind,
          rtpParameters,
          appData,
        }: {
          kind: any;
          rtpParameters: RtpParameters;
          appData: Record<string, string>;
        },
        callback: (id: { id: string }) => void,
        errback: (err: Error) => void
      ) => {
        try {
          let produceType;
          if (appData.kind === "video") {
            produceType = "VIDEO_PRODUCE";
          } else if (
            appData.kind === "audio" &&
            appData.audioNum !== "System"
          ) {
            produceType = "AUDIO_PRODUCE";
          } else if (
            appData.kind === "audio" &&
            appData.audioNum === "System"
          ) {
            produceType = "SCREEN_AUDIO_PRODUCE";
          } else {
            produceType = "SCREEN_PRODUCE";

            // Lower the Video Resolution of current Peer because starting screen share
            if (viewMode === roomViewModeConst.collabMode) {
              this.scaleDownResolutionMethod(
                mediaTypeConstant.video,
                false,
                resolutionConstant.low
              );
            }
            // this.amIsharingScreen = true;
          }
          const produceTypeData = {
            roomname: roomName,
            roomId,
            rtpParameters,
            kind: kind,
          };

          const produceTypeTwoAudioData = {
            roomname: roomName,
            roomId,
            rtpParameters,
            kind: kind,
            audioNum: appData.audioNum,
          };

          const produceScreenTwoTypeData = {
            roomname: roomName,
            roomId,
            rtpParameters,
            kind: kind,
            screenNum: appData.screenNum,
          };
          const producerId: any = await socketInstance.sendMessage(
            produceType,
            appData.screenNum
              ? produceScreenTwoTypeData
              : appData.audioNum
              ? produceTypeTwoAudioData
              : produceTypeData
          );

          callback({ id: producerId.producerId });
        } catch (error: any) {
          console.error(error);
          errback(error);
        }
      }
    );

    this.publishTransport?.on(
      "connectionstatechange",
      async (state: string) => {
        switch (state) {
          case "connecting":
            console.log("ice connecting for publishing");
            break;
          case "connected":
            console.log("iceconnected  for publishing");
            let webrtcIPfetched = localStorage.getItem("webrtcipfetched");

            if (webrtcIPfetched === "false") {
              const stats = await this.publishTransport?.getStats();
              let publicIPofUser;
              stats?.forEach((stat, key) => {
                if (
                  stat.type === "local-candidate" &&
                  stat.candidateType === "srflx" &&
                  stat.url
                ) {
                  console.log("JD IN SRFLX", stat);
                  publicIPofUser = stat.ip;
                  processIPfromWebrtc(publicIPofUser);
                } else {
                  // Localhost case (for dev)
                  if (
                    stat.type === "local-candidate" &&
                    stat.candidateType === "prflx"
                  ) {
                    console.log("JD IN PRFLX", stat);
                    publicIPofUser = stat.ip;
                    processIPfromWebrtc(publicIPofUser);
                  }
                }
              });
            } else {
              console.warn(
                "Not Fetching IP ADDRESS from webrtc because its already fetched."
              );
            }

            // TODO set the local stream into the localvideo tag or audio tag based
            // on camera present or not
            break;
          case "disconnected":
            if (roomSocket && roomSocket.connected) {
              console.log("iceDisconnected  for publishing");
              const iceParameters = (await socketInstance.sendMessage(
                "RESTART_ICE_PRODUCER",
                {
                  roomname: roomName,
                  roomId,
                }
              )) as mediasoupTypes.IceParameters;

              // console.log("iceDisconnected");
              // this.props.setRoomOnRestartIce(this.roomname);

              if (this.publishTransport) {
                this.publishTransport.restartIce({ iceParameters });
              }

              // this.props.clearTempRoomUser();

              // this.props.history.push("/dashboard");
            }
            break;
          case "failed":
            if (this.publishTransport) this.publishTransport.close();
            // Please show an error message. if case failed, out streams are not reaching to server.
            // this.addErrorMessage(
            //   "Publish Transport Connection state failed. Stream not reaching the server"
            // );
            break;
          default:
            break;
        }
      }
    );

    // this.startVideo();
    // // Allow Audio for Agents based on settings
    // if (checkForSupManAdmin(this.props.currentUserRole, "equalTo")) {
    //   this.startAudio();
    // } else {
    //   if (this.audioAllowedInRoom || this.audioAllowedInRoom === undefined) {
    //     this.startAudio();
    //   } else {
    //     toast(`Audio is not allowed in this room`);
    //   }
    // }

    // // this.startScreen();
    // if (checkForSupManAdmin(this.props.currentUserRole, "notEqualTo")) {
    //   if (convertIntoBoolean(this.screenControl)) {
    //     socketInstance.sendMessage("REQUEST_OFFER", {
    //       roomname: this.roomname,
    //     });
    //   }
    // }
  };

  createSubscribingTransport = async (roomSocket: SocketIOClient.Socket) => {
    let roomReducer = store.getState().room;
    let roomId = roomReducer.currentRoomId;
    let roomName = roomReducer.currentRoom;
    if (this.subscribeTransport) {
      this.subscribeTransport.close();
    }
    // this.setState({ presenterChanged: false })
    let subscribeTransportData: any;
    try {
      subscribeTransportData = await socketInstance.sendMessage(
        "CREATE_CONSUMER_TRANSPORT",
        {
          forceTcp: false,
          roomname: roomName,
          roomId,
        }
      );
      console.log('sender-receiver-data', subscribeTransportData)
      subscribeTransportData.iceServers = [];
      subscribeTransportData.iceServers.push({
        urls: "stun:stun.l.google.com:19302",
      });
      if (subscribeTransportData.turnIps) {
        subscribeTransportData.turnIps.forEach((turnIp: string) => {
          subscribeTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=tcp`,
            credential: subscribeTransportData.turnPassword,
            username: subscribeTransportData.turnUserId,
          });
          subscribeTransportData.iceServers.push({
            urls: `turn:${turnIp.trim()}:443?transport=udp`,
            credential: subscribeTransportData.turnPassword,
            username: subscribeTransportData.turnUserId,
          });
        });
      }
      //subscribeTransportData.iceTransportPolicy = "relay";
    } catch (error) {
      // TODO AMIT
      // Please make sure to handle this error
    }

    this.subscribeTransport = this.device?.createRecvTransport(
      subscribeTransportData
    );

    this.subscribeTransport?.on(
      "connect",
      async (
        { dtlsParameters }: mediasoupTypes.TransportOptions,
        callback: () => void,
        errback: () => void
      ) => {
        try {
          await socketInstance.sendMessage("CONNECT_CONSUMER_TRANSPORT", {
            dtlsParameters: dtlsParameters,
            roomname: roomName,
            roomId,
          });
          callback();
        } catch (error: any) {
          errback();
        }
      }
    );

    this.subscribeTransport?.on(
      "connectionstatechange",
      async (state: string) => {
        switch (state) {
          case "connecting":
            console.log("connnecting ice consumer ");
            break;
          case "connected":
            console.log("connnect ice consumer ");

            break;
          case "disconnected":
            if (roomSocket.connected) {
              console.log("disconnected ice consumer ");
              const iceParameters = (await socketInstance.sendMessage(
                "RESTART_ICE_CONSUMER",
                {
                  roomname: roomName,
                  roomId,
                }
              )) as mediasoupTypes.IceParameters;
              if (this.subscribeTransport) {
                this.subscribeTransport.restartIce({ iceParameters });
              }
            }
            break;
          case "failed":
            if (this.subscribeTransport) this.subscribeTransport.close();
            // TODO AMIT
            // Please show an error message. if case failed, streams are not coming from server.
            break;
          default:
            break;
        }
      }
    );
  };

  replaceAudioTrack = async (track: MediaStreamTrack) => {
    if (track && this.audioProducer)
      await this.audioProducer.replaceTrack({ track: track.clone() });
  };

  replaceVideoTrack = async (track: MediaStreamTrack) => {
    // @ts-ignore
    if (track) await this.videoProducer.replaceTrack({ track: track.clone() });
  };

  scaleDownResolutionMethod = async (
    mediaType: string,
    allStream: boolean,
    resolution: string
  ) => {
    let roomReducer = store.getState().room;

    if (
      this.videoProducer &&
      this.videoProducer.rtpSender &&
      (mediaType === mediaTypeConstant.video || allStream)
    ) {
      let videoParams = this.videoProducer.rtpSender.getParameters();
      if (videoParams && videoParams.encodings.length) {
        //@ts-ignore
        videoParams.encodings[0].scaleResolutionDownBy = roomReducer.videoScale[resolution];
        await this.videoProducer.rtpSender.setParameters(videoParams);
      }
    }

    if (
      this.screenProducer &&
      this.screenProducer.rtpSender &&
      (mediaType === mediaTypeConstant.screen || allStream)
    ) {
      let screenParams = this.screenProducer.rtpSender.getParameters();

      if (screenParams && screenParams.encodings.length) {
        //@ts-ignore
        screenParams.encodings[0].scaleResolutionDownBy = roomReducer.screenScale[resolution];
        await this.screenProducer.rtpSender.setParameters(screenParams);
      }
    }

    if (
      this.screenTwoProducer &&
      this.screenTwoProducer.rtpSender &&
      (mediaType === mediaTypeConstant.screentwo || allStream)
    ) {
      let screenTwoparams = this.screenTwoProducer.rtpSender.getParameters();
      if (screenTwoparams && screenTwoparams.encodings.length) {
        //@ts-ignore
        screenTwoparams.encodings[0].scaleResolutionDownBy = roomReducer.screenScale[resolution];
        await this.screenTwoProducer.rtpSender.setParameters(screenTwoparams);
      }
    }
  };

  clearMediaSoupService =  () => {
    return new Promise( (resolve) => {



      if (this.audioProducer) {
        this.audioProducer.close();
        this.audioProducer = undefined;
      }
      if (this.screenProducer) {
        this.screenProducer.close();
        this.screenProducer = undefined;
      }
      if (this.screenTwoProducer) {
        this.screenTwoProducer.close();
        this.screenTwoProducer = undefined;
      }

      if (this.audioFileProducer) {
        this.audioFileProducer.close();
        this.audioFileProducer = undefined;
      }
      if (this.videoProducer) {
        this.videoProducer.close();
        this.videoProducer = undefined;
      }
      if (this.screenAudioProducer) {
        this.screenAudioProducer.close();
        this.screenAudioProducer = undefined;
      }

      if (this.publishTransport) {
        this.publishTransport.close();
       //@ts-ignore
       this.publishTransport = undefined;
     }
     if (this.subscribeTransport) {
       this.subscribeTransport.close();
       //@ts-ignore
       this.subscribeTransport = undefined;
     }

      if (this.device) {
        //@ts-ignore
        this.device = undefined;
      }
          resolve(true);

    });

  };
}

export default class MediasoupServiceSingleton {
  static instance: MediasoupService;
  constructor() {
    if (!MediasoupServiceSingleton.instance) {
      MediasoupServiceSingleton.instance = new MediasoupService();
    }
  }

  getInstance() {
    return MediasoupServiceSingleton.instance;
  }
}
