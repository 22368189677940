import React, { Fragment, useEffect, useRef, useState } from "react";
import Video from "../Video";
import { getInitials } from "../../utils/initials";
import {
  EllipsisVerticalIcon,
  FolderMinusIcon,
  MicrophoneIcon,
  VideoCameraIcon,
  ViewfinderCircleIcon,
  StopCircleIcon,
  XMarkIcon,
  ArrowUpOnSquareIcon,
  TvIcon,
} from "@heroicons/react/24/outline";
import { InstructorScreenProps } from "../../interfaces/componentsInterface/InstructorScreen";
import streamService from "../../services/streamService";
import { useAppSelector } from "../../app/hooks";
import { mediaSelector } from "../../features/media/mediaSlice";
import Audio from "../Audio";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import ScreenControl from "../ScreenControl";
import { peerVolumeSelector, setPeerVolume } from "../../features/peerVolume/peerVolumeSlice";
import { getVolumeClasses } from "../../utils/audio";
import { store } from "../../app/store";
import {
  AGENT_MIC_ACCESS,
  AGENT_SCREENS_ACCESS,
  emojiEventConst,
  mediaTypeConstant,
  OTHER_CAMERA_ACCESS_TO_SUPERVISOR,
  REMOVE_HANDRAISE_OF_OTHERS,
  role,
  roomViewModeConst,
  socketConnectiontype,
  SUPERVISOR_CAN_OBSERVE,
} from "../../utils/constant";
import { privateCallSelector } from "../../features/privateCall/privateCallSlice";
import { observeSelector } from "../../features/observe/observeSlice";
import SocketService from "../../services/socket";
import { roomSelector } from "../../features/room/roomSlice";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { RemotePeerData } from "../../interfaces/componentsInterface/mediaInterface";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import {
  screenControlSelector,
  setIsScreenControl,
  setOpenentPeerId,
} from "../../features/screenControl/screenControlSlice";
import { useDispatch } from "react-redux";
import { constantMethod } from "../../utils/constantMethod";
import DefaultImage from '../../assets/images/blur.jpg'
import handleClickOutside from "../../utils/click";
import { chatSelector } from "../../features/chat/chatSlice";
import { notifyMe } from "../../utils/desktopNotification";
const socketInstanceDesktopApp = new SocketService(
  socketConnectiontype.desktopApp
).getInstance(socketConnectiontype.desktopApp);
const streamInstance = new streamService().getInstance();

const mediaSocketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const socketInstanceChat = new SocketService(
  socketConnectiontype.chat
).getInstance(socketConnectiontype.chat);

export default function InstructorScreen({
  makePrivateCall,
  tflite
}: InstructorScreenProps): ReturnType<React.FC> {
  const dispatch = useDispatch();
  const {
    remotePeers,
    remotePeersId,
    startIndexForRemotePeer,
    endIndexForRemotePeer,
    presenter,
    videoAccessible
  } = useAppSelector(mediaSelector);
  const loggedInUser = useAppSelector(userDetailsSelector);
  const environments = useAppSelector(environmentSelector);
  const user = useAppSelector(userDetailsSelector);
  const roomReducer = useAppSelector(roomSelector);
  const [imageError, setImageError] = useState(false);
  const chatReducer = useAppSelector(chatSelector);

  const spacesConfig =
    environments.environments &&
      environments.environments.REACT_APP_SPACES_CONFIG
      ? environments.environments.REACT_APP_SPACES_CONFIG.data[0]
      : "";

  const agentCameraAccess =
    environments.environments &&
      environments.environments.OTHER_CAMERA_ACCESS_TO_SUPERVISOR
      ? environments.environments.OTHER_CAMERA_ACCESS_TO_SUPERVISOR.status
      : OTHER_CAMERA_ACCESS_TO_SUPERVISOR;

  const supervisorCanObserve =
    environments.environments &&
      environments.environments.SUPERVISOR_CAN_OBSERVE
      ? environments.environments.SUPERVISOR_CAN_OBSERVE.status
      : SUPERVISOR_CAN_OBSERVE;

  const agentScreenAccess =
    environments.environments && environments.environments.AGENT_SCREENS_ACCESS
      ? environments.environments.AGENT_SCREENS_ACCESS.status
      : AGENT_SCREENS_ACCESS;

  const agentMicAccess =
    environments.environments && environments.environments.AGENT_MIC_ACCESS
      ? environments.environments.AGENT_MIC_ACCESS.status
      : AGENT_MIC_ACCESS;
  const isElectronTrue =
    environments.environments &&
      environments.environments.REACT_APP_SCREENCONTROL
      ? environments.environments.REACT_APP_SCREENCONTROL.status
      : "";

  const screenControl = environments.environments.REACT_APP_SCREENCONTROL
    ? environments.environments.REACT_APP_SCREENCONTROL.status
    : false;

  const supervisorCanRemoveHandraise = environments.environments.REMOVE_HANDRAISE_OF_OTHERS
    ? environments.environments.REMOVE_HANDRAISE_OF_OTHERS.status
    : REMOVE_HANDRAISE_OF_OTHERS;

  const [controlledUserId, setControlledUserId] = useState<string>("");
  console.log({ controlledUserId })
  const [isScreenControlModalOpen, setIsScreenControlModalOpen] =
    useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const peerVolume = useAppSelector(peerVolumeSelector);
  const { isScreenControl } = useAppSelector(screenControlSelector);
  // const media = useAppSelector(mediaSelector)
  const { isPrivateCallOn, callModalOption, openentCallerUserId } =
    useAppSelector(privateCallSelector);
  const { openentObserveeAudioId, openentObserverAudioId } =
    useAppSelector(observeSelector);
  const environmentReducer = useAppSelector(environmentSelector);
  const { currentRoom, currentRoomId, viewMode, isAudioAllowedInRoom } =
    useAppSelector(roomSelector);
  const [currentPeerUserId, setCurrentPeerUserId] = useState<string>("");
  const instructorControlRef = useRef(null);
  const [customFeedbackValue, setCustomFeedbackValue] = useState<string>("");
  const userIdRef = useRef<HTMLDivElement>(null);

  function closeScreenModal() {
    setIsScreenControlModalOpen(false);
    setControlledUserId("");
    socketInstanceDesktopApp.sendMessage(
      "APP_CONTROL_DISCONNECT_BY_SUPERVISORUSER",
      {}
    );
    dispatch(setOpenentPeerId(""));
    dispatch(setIsScreenControl(false));
    // dispatch(clearControlId());
    // setStream(null)
    // setIsObserveParticipantScreenStopped(false);
  }

  function openScreenModal() {
    // setIsObserveParticipantScreenStopped(isScreenStopped);
    // setStream(screenStream);
    setIsScreenControlModalOpen(true);
  }

  const getFlexWidthAndHeight = (peersLength: number, itemNo: number) => {
    let height;
    let width;

    if (peersLength === 7 && itemNo > 3) {
      width = 35;
      height = 32;
    } else if (
      (peersLength === 13 && itemNo > 4) ||
      (peersLength === 14 && itemNo > 8)
    ) {
      width = 28;
      height = 22;
    } else if (peersLength === 1) {
      width = 95;
      height = 100;
    } else if (peersLength === 2 || peersLength === 4 || peersLength === 3) {
      width = 45;
      height = (peersLength === 4 || peersLength === 3) ? 47 : 96;
    } else if (peersLength >= 5 && peersLength <= 9) {
      width = 33;
      height = peersLength <= 6 ? 47 : 32;
    } else if (peersLength <= 16 && peersLength > 9) {
      width = 23;
      height = peersLength <= 12 ? 30 : 22;
    }

    return { height, width };
  };



  const handleParticipantCardOptionsClick = (userId: string) => {
    // console.log({peer})
    // console.log({index,peer,screen})
    if (controlledUserId === userId && controlledUserId !== "") {
      setControlledUserId("");
    } else {
      setControlledUserId(userId);
    }
    // setActiveUsername(activeUserName === userName ? null : userName);
  };

  const handleCustomFeedbackCheck = (peerUserId: string) => {
    if (customFeedbackValue === "") {
      toast.error("Feedback Can't be empty!");
      return;
    }

    handleFeedbackSubmit(customFeedbackValue, peerUserId);
    setCustomFeedbackValue("");
  };

  const handleFeedbackClick = (peerUserId: string) => {
    mediaSocketInstance.sendMessage("FEEDBACK_IMG_UPLOAD", {
      peerUserId: peerUserId,
      parentUserId:loggedInUser.userId,
      parentRole: loggedInUser.role,
      parentUsername: loggedInUser.username,
    });

    setCurrentPeerUserId(peerUserId);
    setIsFeedbackModalOpen(true);
    setControlledUserId("");
  };

  const handleFeedbackSubmit = (message: string, peerUserId: string) => {
    mediaSocketInstance.sendMessage("FEEDBACK_IMG_UPLOAD_API", {
      peerUserId: peerUserId,
      comment: message,
    });
    setIsFeedbackModalOpen(false);
    // setExportModal(false);
    // setShowDropdown(false);
  };

  const promoteUser = (peerUserId: string) => {
    mediaSocketInstance.sendMessage("PROMOTE_USER", {
      roomname: currentRoom,
      peerUserId,
    });
    setControlledUserId("");
  };

  const handleRemoveHandraise = ( peerUserId: string, peerUsername: string, peerName: string) =>{
    // Action to perform when toggling OFF
    socketInstanceChat.sendMessage("EMOJI_OTHER_ACTIONS", {
      roomname: roomReducer.currentRoom,
      roomId: roomReducer.currentRoomId,
      type: "",
      userId: peerUserId,
      supHandRaiseRemoveUsername: peerName,
      supHandRaiseRemoveFlag : true,
    });
    toast(peerName + " Handraise removed by " + user.firstName + " " + user.lastName );
    notifyMe(peerName + " Handraise removed by " + user.firstName + " " + user.lastName );
    setControlledUserId("");
  }

  const handleRemoveParticipant = (
    peerUserId: string,
    peerUsername: string,
    peerName: string
  ) => {
    mediaSocketInstance.sendMessage("REMOVE_USER", {
      peerUserId,
      peerUsername: peerUsername,
      peerName,
    });
  };

  const handleScreenControl = (data: any) => {
    if (
      streamInstance &&
      streamInstance?.peerRemoteStream &&
      streamInstance.peerRemoteStream[controlledUserId].screenStream
    ) {
      openScreenModal();
      dispatch(setOpenentPeerId(data.peerUserId));
      dispatch(setIsScreenControl(true));
      socketInstanceDesktopApp.sendMessage(
        "APP_SENDING_REQUEST_FOR_CONTROL_SCREEN",
        {
          // openentPeerSessionId: data.peerSessionId,
          // userName: user.username,
          // openentUserName: data.peerUsername,
          // peerSessionId: a.socketId,
          openentUserId: data.peerUserId,
          userId: user.userId,
        }
      );
      setControlledUserId("");
    } else {
      setControlledUserId("");
      toast("Participant is not sharing screen", {
        icon: "📌",
      });
    }
  };

  const videoPauseOrUnpause = (peerUserId: string) => {
    const checkVideoPaused = remotePeers[peerUserId].peerVideoPaused;

    if (checkVideoPaused) {
      streamInstance.unpauseVideo(peerUserId,tflite);
    } else {
      streamInstance.pauseVideo(peerUserId);
    }
  };

  const audioPauseOrUnpause = (peerUserId: string) => {
    const checkAudioPaused = remotePeers[peerUserId].peerAudioPaused;

    if (checkAudioPaused) {
      streamInstance.unpauseLocalMic(peerUserId);
    } else {
      streamInstance.pauseLocalMic(peerUserId);
      dispatch(setPeerVolume({ volume: -100, peerUserId: peerUserId }));
    }
  };

  const handleStartAndStopObserveAgent = (
    peerUserId: string,
    openentObserverAudioId?: string
  ) => {
    if (
      (openentObserveeAudioId !== "" &&
        openentObserveeAudioId !== peerUserId) ||
      (openentObserverAudioId !== "" && openentObserveeAudioId === "")
    ) {
      console.log("inside stop");
      return;
    }
    if (openentObserveeAudioId === peerUserId) {
      mediaSocketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
        roomname: currentRoom,
        roomId: currentRoomId,
        userId: peerUserId,
        from: "stop",
      });
    } else {
      mediaSocketInstance.sendMessage("OBSERVE_AUDIO_AGENT", {
        roomname: currentRoom,
        userId: peerUserId,
      });
    }
    setControlledUserId("");
  };

  const handlePrivateCall = (peerUser: RemotePeerData) => {
    if (
      (peerUser.isPrivateCallOn &&
        isPrivateCallOn &&
        openentCallerUserId === peerUser.peerUserId) ||
      (!peerUser.isPrivateCallOn && !isPrivateCallOn)
    ) {
      // if (mediaReducer.isScreenAudioShared) {
      if (false) {
        toast(
          "You are sharing your Screen with Audio. Please Stop screen or share screen without audio to make private call"
        );
        return;
      }
      if (
        ((!peerUser.isPrivateCallOn && !peerUser.isCallModalOn) ||
          openentCallerUserId === peerUser.peerUserId) &&
        makePrivateCall
      )
        makePrivateCall(peerUser.peerUserId);
    }
    setControlledUserId("");
  };

  const shareUnshareOpenentPeerScreen = (peerUserId: string) => {
    const peer = remotePeers[peerUserId];
    if (peer) {
      if (peer.peerScreenStopped) {
        streamInstance.shareScreen(peerUserId);
      } else {
        streamInstance.unshareScreen(peerUserId);
      }
    }
  };

  const shareUnshareOpenentPeerScreenTwo = (peerUserId: string) => {
    const peer = remotePeers[peerUserId];

    if (peer.peerscreenTwoStopped) {
      streamInstance.shareScreenTwo(peerUserId);
    } else {
      streamInstance.unshareScreenTwo(peerUserId);
    }
  };



  const handleSwap = (peerUserId: string) => {
    const parentDiv = document.getElementById(peerUserId);
    if (!parentDiv) return;


    const primaryVideo = parentDiv.querySelector('video');
    const innerDiv = parentDiv.querySelector('div');
    const innerVideos = innerDiv ? Array.from(innerDiv.getElementsByTagName('video')) : [];

    if (primaryVideo && innerDiv && innerVideos.length > 0) {

      const primaryVideoSrcObject = primaryVideo.srcObject;
      const primaryVideoSrc = primaryVideo.src;
      const primaryVideoMediaType = primaryVideo.getAttribute('data-mediaType');


      primaryVideo.srcObject = innerVideos[0].srcObject;
      primaryVideo.src = innerVideos[0].src
      // @ts-ignore
      primaryVideo.setAttribute('data-mediaType', innerVideos[0].getAttribute('data-mediaType'));

      innerVideos[0].srcObject = primaryVideoSrcObject;
      innerVideos[0].src = primaryVideoSrc;
      // @ts-ignore
      innerVideos[0].setAttribute('data-mediaType', primaryVideoMediaType);

      for (let i = 0; i < innerVideos.length - 1; i++) {
        const tempSrcObject = innerVideos[i].srcObject;
        const tempSrc = innerVideos[i].src;
        const tempMediaType = innerVideos[i].getAttribute('data-mediaType');

        innerVideos[i].srcObject = innerVideos[i + 1].srcObject;
        innerVideos[i].src = innerVideos[i + 1].src;
        // @ts-ignore
        innerVideos[i].setAttribute('data-mediaType', innerVideos[i + 1].getAttribute('data-mediaType'));


        innerVideos[i + 1].srcObject = tempSrcObject;
        innerVideos[i + 1].src = tempSrc;
        // @ts-ignore
        innerVideos[i + 1].setAttribute('data-mediaType', tempMediaType);

      }
    }
  }


  const instructorControlsOptions = () => {
    setControlledUserId("");
  }
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => handleClickOutside(e, instructorControlRef, instructorControlsOptions);

    if (controlledUserId !== "") {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [controlledUserId]);

  useEffect(() => {
    if (!isScreenControl) {
      setIsScreenControlModalOpen(false);
      setControlledUserId("");
    }

  }, [isScreenControl])

  return (
    <>
      {/* // Here val === peerUserId  */}
      <div className="h-full w-full rounded-xl flex justify-center items-center flex-wrap gap-1 ">
        {remotePeersId
          .slice(startIndexForRemotePeer, endIndexForRemotePeer)
          .map((val: string, index) => {
            let remotePeer = remotePeers[val];
            let flexObj = getFlexWidthAndHeight(
              remotePeersId.slice(startIndexForRemotePeer, endIndexForRemotePeer).length,
              index + 1
            );
            const sessionData = chatReducer.onlineUsers.find(
              (data) => data.peerUserId === remotePeer.peerUserId
            );

            return (
              <div
                style={{
                  width: flexObj.width + "%",
                  height: flexObj.height + "%",
                }}
                className="rounded-xl"
                key={remotePeer.peerUserId}
              >
                {remotePeer.peerVideoPaused &&
                  remotePeer.peerScreenStopped &&
                  remotePeer.peerscreenTwoStopped ? (
                  <div style={{ backgroundImage: `url(${remotePeers[val].imageUrl ? remotePeers[val].imageUrl : DefaultImage} )` }}
                    className=" bg-white bg-cover  shadow-xl rounded-xl h-full w-full flex  justify-center items-center gap-1 relative">
                    <div className="w-full h-full rounded-xl flex  justify-center items-center gap-1 backdrop-blur-md ">
                      <div className=" w-full flex justify-between items-center absolute top-0 p-1">
                        <div className=" flex justify-center items-center">
                          {/* {agentCameraAccess && (
                          <VideoCameraIcon
                            className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-3 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
                            title="Turn Off Video"
                            onClick={() => videoPauseOrUnpause(val)}
                          />
                        )} */}
                          {remotePeers[val].videoAccessible &&
                          (agentCameraAccess && (
                            remotePeer.peerVideoPaused ? (
                              <div
                                className="relative flex justify-center items-center"
                                onClick={() => videoPauseOrUnpause(val)}
                              >
                                <div className="lg:border-t-[1.4px] xl:border-t-2 border-white lg:w-[40%] xl:w-[43%] absolute -translate-x-1/2 lg:right-[25%] xl:right-[20%] rotate-45 z-10 cursor-pointer" ></div>
                                <VideoCameraIcon
                                  className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-[9] bg-blue text-white"
                                  title="Turn On Video"

                                />
                              </div>
                            ) : (
                              <VideoCameraIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-gray-50 text-gray-500"
                                title="Turn Off Video"
                                onClick={() => videoPauseOrUnpause(val)}
                              />
                            )
                          ))}
                          {/* {(agentMicAccess && isAudioAllowedInRoom) && (
                          <MicrophoneIcon
                            className="w-6   text-gray-500 p-1 rounded-full shadow-md  bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
                            title="Mute"
                            onClick={() => audioPauseOrUnpause(val)}
                          />
                        )} */}

                          {(agentMicAccess && isAudioAllowedInRoom) && (
                            remotePeer.peerAudioPaused ? (
                              <div
                                className="relative flex justify-center items-center"
                                onClick={() => audioPauseOrUnpause(val)}
                              >
                                <div className="lg:border-t-[1.4px] xl:border-t-2 border-white lg:w-[40%] xl:w-[43%] absolute -translate-x-1/2 lg:right-[25%] xl:right-[20%] rotate-45 z-10 cursor-pointer" ></div>
                                <MicrophoneIcon
                                  className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer bg-blue text-white transition-all duration-100 hover:opacity-80 z-[9]"
                                  title="Unmute"
                                />
                              </div>
                            ) : (
                              <MicrophoneIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-gray-50 text-gray-500"
                                title="Mute"
                                onClick={() => audioPauseOrUnpause(val)}
                              />
                            )
                          )}

                          {agentScreenAccess && (
                            remotePeer.peerScreenStopped ? (
                              <TvIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-gray-50 text-gray-500"
                                title="Share Screen"
                                onClick={() => shareUnshareOpenentPeerScreen(val)}
                              />
                            ) : (
                              <div className="relative flex justify-center items-center">
                                <TvIcon
                                  className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-white text-blue"
                                  title="Unshare Screen"
                                  onClick={() => shareUnshareOpenentPeerScreen(val)}
                                />
                                {
                                (remotePeers[val].peerType !== "Agent" || (!screenControl && !remotePeers[val].enableDesktopApp)  ) && 
                                  <p className="bg-blue text-white rounded-full p-1 absolute right-2 -bottom-2 w-4 h-4 flex justify-center items-center text-[.6rem] z-10">
                                    1
                                  </p>
                                }
                              </div>
                            )
                          )}

                          {(!remotePeer.peerScreenStopped ||
                            !remotePeer.peerscreenTwoStopped) &&
                            agentScreenAccess &&
                            viewMode !== roomViewModeConst.collabMode &&
                            (remotePeers[val].peerType !== "Agent" || (!screenControl && !remotePeers[val].enableDesktopApp)  ) ? (
                            remotePeer.peerscreenTwoStopped ? (
                              <p className="relative flex justify-center items-center">
                                <TvIcon
                                  className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 text-gray-500 bg-gray-50"
                                  title="Share Screen Two"
                                  onClick={() => shareUnshareOpenentPeerScreenTwo(val)}
                                />
                                <p className="bg-gray-500 text-white rounded-full p-1 absolute right-2 -bottom-2 w-4 h-4 flex justify-center items-center text-[.6rem] z-10">
                                  2
                                </p>
                              </p>
                            ) : (
                              (remotePeers[val].peerType !== "Agent" || (!screenControl && !remotePeers[val].enableDesktopApp)  ) && 
                              <p className="relative flex justify-center items-center">
                                <TvIcon
                                  className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 text-blue bg-white"
                                  title="Unshare Screen Two"
                                  onClick={() => shareUnshareOpenentPeerScreenTwo(val)}
                                />
                                <p className="bg-blue text-white rounded-full p-1 absolute right-2 -bottom-2 w-4 h-4 flex justify-center items-center text-[.6rem] z-10">
                                  2
                                </p>
                              </p>
                            )
                          ) : null}
                        </div>
                        <EllipsisVerticalIcon
                          className="w-6  text-gray-500 p-1 rounded-full shadow-md  bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
                          title="Options"
                          onClick={() => handleParticipantCardOptionsClick(val)}
                        />
                      </div>
                      {remotePeers[val].imageUrl && imageError ? (
                        <img
                          className={`inline-block h-28 w-28 rounded-full ring-2 ring-white ${peerVolume &&
                            getVolumeClasses(
                              peerVolume[remotePeers[val].peerUserId]
                            )
                            }`}
                          src={remotePeers[val].imageUrl}
                          alt="User"
                        />
                      ) : remotePeers[val].imageUrl && !imageError ? (
                        <img
                          className={`inline-block h-28 w-28 rounded-full ring-2 ring-white  ${peerVolume &&
                            getVolumeClasses(
                              peerVolume[remotePeers[val].peerUserId]
                            )
                            }`}
                          src={generateThumbnailsUrl(
                            remotePeers[val]?.imageUrl.split("/").pop(),
                            480,
                            loggedInUser.custId,
                            remotePeers[val].peerUserId,
                            spacesConfig
                          )}
                          alt="User"
                          onError={() => setImageError(true)}
                        />
                      ) : (
                        <p
                          className={`  w-10 h-10 p-8  bg-blue rounded-full text-white flex justify-center items-center font-semibold border-4 border-white  ring-2  ring-white shadow-xl ${peerVolume &&
                            getVolumeClasses(
                              peerVolume[remotePeers[val].peerUserId]
                            )
                            }`}
                        >
                          {getInitials(remotePeers[val].peerName)}
                        </p>
                      )}

                      {/* <p className={` w-10 h-10 p-8  bg-blue rounded-full text-white flex justify-center items-center font-semibold ${peerVolume && getVolumeClasses(peerVolume[remotePeers[val].peerUserId])}`}>
                      {getInitials(remotePeers[val].peerName)}
                    </p> */}

                      <p className="lg:text-[.6rem] xl:text-[.9rem] p-[.5px] px-[2px] capitalize  absolute bottom-1 left-1 rounded-md  text-white bg-gray-500 backdrop-blur-sm opacity-70">
                        {remotePeer.peerName}
                      </p>
                      {controlledUserId === val && (
                        <div className=" absolute top-4 right-5 bg-white overflow-y-auto p-1 rounded-xl shadow-md w-[15vw] transition duration-500 z-10  " ref={instructorControlRef}>
                          {/* {agentScreenAccess && (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            key="Screen Share"
                            onClick={() => shareUnshareOpenentPeerScreen(val)}
                          >
                            <ViewfinderCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " />
                            <p className="md:lg:sm:text-xs xl:text-sm">
                              {remotePeer.peerScreenStopped
                                ? "Share Screen"
                                : "Unshare Screen"}
                            </p>
                          </div>
                        )} */}
                          {/* {(!remotePeer.peerScreenStopped ||
                          !remotePeer.peerscreenTwoStopped) &&
                          agentScreenAccess &&
                          viewMode !== roomViewModeConst.collabMode &&
                          !screenControl ? (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            key="Screen Share Two"
                            onClick={() =>
                              shareUnshareOpenentPeerScreenTwo(val)
                            }
                          >
                            <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " />
                            <p className="md:lg:sm:text-xs xl:text-sm">
                              {remotePeer.peerscreenTwoStopped
                                ? "Share Screen Two"
                                : "Unshare Screen Two"}
                            </p>
                          </div>
                        ) : null} */}



                          {isAudioAllowedInRoom && !isPrivateCallOn &&
                            !callModalOption.isCallModal &&
                            remotePeer.peerAudioPaused &&
                            !remotePeer.isCallModalOn &&
                            !remotePeer.isPrivateCallOn &&
                            remotePeer.peerType === role.AGENT &&
                            supervisorCanObserve ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              key="Observe"
                              onClick={() =>
                                handleStartAndStopObserveAgent(
                                  remotePeer.peerUserId,
                                  remotePeer.openentObserverAudioId
                                )
                              }
                            >
                              {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                              <p className="md:lg:sm:text-xs xl:text-sm">
                                {openentObserveeAudioId === remotePeer.peerUserId
                                  ? "Stop Observing"
                                  : openentObserveeAudioId !== "" &&
                                    openentObserveeAudioId !==
                                    remotePeer.peerUserId
                                    ? "Can't Observe"
                                    : remotePeer.openentObserverAudioId !== ""
                                      ? "Already Observed"
                                      : "Observe"}
                              </p>
                            </div>
                          ) : null}
                          {remotePeer.peerType === role.AGENT && (screenControl || remotePeers[val].enableDesktopApp)  && !remotePeer.peerScreenStopped ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              key="screen control"
                              onClick={() =>
                                handleScreenControl(remotePeers[val])
                              }
                            >
                              {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                              <p className="md:lg:sm:text-xs xl:text-sm">
                                {"Screen control"}
                              </p>
                            </div>
                          ) : null}

                          {sessionData && sessionData.emojiForHandAndLunchPeer==="" && isAudioAllowedInRoom &&
                            ((!isPrivateCallOn && !callModalOption.isCallModal) ||
                              openentCallerUserId === remotePeer.peerUserId ? (
                              <div
                                className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                                key="Private Call"
                                onClick={() => handlePrivateCall(remotePeer)}
                              >
                                {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                                <p className="md:lg:sm:text-xs xl:text-sm">
                                  {isPrivateCallOn &&
                                    remotePeer.isPrivateCallOn &&
                                    openentCallerUserId === remotePeer.peerUserId
                                    ? "Stop Private Call"
                                    : isPrivateCallOn &&
                                      !remotePeer.isPrivateCallOn
                                      ? "Your private call on"
                                      : remotePeer.isPrivateCallOn ||
                                        (!remotePeer.isPrivateCallOn &&
                                          remotePeer.isCallModalOn)
                                        ? "Already in Call"
                                        : "Private Call"}
                                </p>
                              </div>
                            ) : null)}

                          {constantMethod.checkHigherRole(loggedInUser.role) && roomViewModeConst.lectureMode === viewMode ? <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            onClick={() => promoteUser(remotePeer.peerUserId)}
                          >
                            <p
                              title={"Promote User"}
                              className="md:lg:sm:text-xs xl:text-sm"
                            >
                              Promote User
                            </p>
                          </div> : null}


                          {constantMethod.checkHigherRole(loggedInUser.role) ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              onClick={() =>
                                handleRemoveParticipant(
                                  remotePeer.peerUserId,
                                  remotePeer.peerUsername,
                                  remotePeer.peerName
                                )
                              }
                            >
                              <p
                                title={"Remove Participant"}
                                className="md:lg:sm:text-xs xl:text-sm"
                              >
                                Remove Participant
                              </p>
                            </div>
                          ) : null}

                            {constantMethod.checkHigherRole(loggedInUser.role) && supervisorCanRemoveHandraise &&
                              store.getState().chat.onlineUsers.some(
                                (user: any) =>
                                  user.userId === remotePeer.peerUserId &&
                                  user.emojiForIconPeer === emojiEventConst.HANDRAISE
                              ) ? (
                              <div
                                className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                                onClick={() =>
                                  handleRemoveHandraise(
                                    remotePeer.peerUserId,
                                    remotePeer.peerUsername,
                                    remotePeer.peerName
                                  )
                                }
                              >
                                <p
                                  title={"Remove Handraise"}
                                  className="md:lg:sm:text-xs xl:text-sm"
                                >
                                  Remove Handraise
                                </p>
                              </div>
                            ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={` bg-white border-[2px]  shadow-xl rounded-xl h-full w-full flex flex-col justify-start items-start gap-1 relative ${peerVolume && peerVolume[remotePeer.peerUserId] !== -100
                      ? "border-blue"
                      : "border-transparent"
                      }`}
                  >
                    <div className=" w-full flex justify-between items-center absolute top-0 p-1 ">
                      <div className=" flex justify-center items-center">
                        {/* {agentCameraAccess && (
                          <VideoCameraIcon
                            className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-3 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 z-10"
                            title="Turn Off Video"
                            onClick={() => videoPauseOrUnpause(val)}
                          />
                        )} */}
                        {remotePeers[val].videoAccessible && (agentCameraAccess && (
                          remotePeer.peerVideoPaused ? (
                            <div
                              className="relative flex justify-center items-center"
                              onClick={() => videoPauseOrUnpause(val)}
                            >
                              <div className="lg:border-t-[1.4px] xl:border-t-2 border-white lg:w-[40%] xl:w-[43%] absolute -translate-x-1/2 lg:right-[25%] xl:right-[20%] rotate-45 z-10 cursor-pointer" ></div>
                              <VideoCameraIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-[9] bg-blue text-white"
                                title="Turn On Video"
                              />
                            </div>
                          ) : (
                            <VideoCameraIcon
                              className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-gray-50 text-gray-500"
                              title="Turn Off Video"
                              onClick={() => videoPauseOrUnpause(val)}
                            />
                          )
          ))}
                        {/* {(agentMicAccess && isAudioAllowedInRoom) && (
                          <MicrophoneIcon
                            className="w-6   text-gray-500 p-1 rounded-full shadow-md  bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 z-10"
                            title="Mute"
                            onClick={() => audioPauseOrUnpause(val)}
                          />
                        )} */}
                        {(agentMicAccess && isAudioAllowedInRoom) && (
                          remotePeer.peerAudioPaused ? (
                            <div
                              className="relative flex justify-center items-center"
                              onClick={() => audioPauseOrUnpause(val)}
                            >
                              <div className="lg:border-t-[1.4px] xl:border-t-2 border-white lg:w-[40%] xl:w-[43%] absolute -translate-x-1/2 lg:right-[25%] xl:right-[20%] rotate-45 z-10 cursor-pointer" ></div>
                              <MicrophoneIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer   bg-blue text-white transition-all duration-100 hover:opacity-80 z-[9]"
                                title="Unmute"
                              />
                            </div>
                          ) : (
                            <MicrophoneIcon
                              className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-gray-50 text-gray-500"
                              title="Mute"
                              onClick={() => audioPauseOrUnpause(val)}
                            />
                          )
                        )}

                        {agentScreenAccess && (
                          remotePeer.peerScreenStopped ? (
                            <TvIcon
                              className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-gray-50 text-gray-500"
                              title="Share Screen"
                              onClick={() => shareUnshareOpenentPeerScreen(val)}
                            />
                          ) : (
                            <div className="relative flex justify-center items-center">
                              <TvIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 bg-white text-blue"
                                title="Unshare Screen"
                                onClick={() => shareUnshareOpenentPeerScreen(val)}
                              />
                              {
                                 (remotePeers[val].peerType !== "Agent" || (!screenControl && !remotePeers[val].enableDesktopApp)  ) &&
                                <p className="bg-blue text-white rounded-full p-1 absolute right-2 -bottom-2 w-4 h-4 flex justify-center items-center text-[.6rem] z-10">
                                  1
                                </p>
                              }
                            </div>
                          )
                        )}

                        {(!remotePeer.peerScreenStopped ||
                          !remotePeer.peerscreenTwoStopped) &&
                          agentScreenAccess &&
                          viewMode !== roomViewModeConst.collabMode &&
                          (remotePeers[val].peerType !== "Agent" || (!screenControl && !remotePeers[val].enableDesktopApp)  ) ? (
                          remotePeer.peerscreenTwoStopped ? (
                            <p className="relative flex justify-center items-center">
                              <TvIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 text-gray-500 bg-gray-50"
                                title="Share Screen Two"
                                onClick={() => shareUnshareOpenentPeerScreenTwo(val)}
                              />
                              <p className="bg-gray-500 text-white rounded-full p-1 absolute right-2 -bottom-2 w-4 h-4 flex justify-center items-center text-[.6rem] z-10">
                                2
                              </p>
                            </p>
                          ) : (
                            (remotePeers[val].peerType !== "Agent" || (!screenControl && !remotePeers[val].enableDesktopApp)  ) &&
                            <p className="relative flex justify-center items-center">
                              <TvIcon
                                className="w-6 p-1 rounded-full shadow-md mr-3 cursor-pointer transition-all duration-100 hover:opacity-80 z-10 text-blue bg-white"
                                title="Unshare Screen Two"
                                onClick={() => shareUnshareOpenentPeerScreenTwo(val)}
                              />
                              <p className="bg-blue text-white rounded-full p-1 absolute right-2 -bottom-2 w-4 h-4 flex justify-center items-center text-[.6rem] z-10">
                                2
                              </p>
                            </p>
                          )
                        ) : null}
                      </div>
                      <EllipsisVerticalIcon
                        className="w-6  text-gray-500 p-1 rounded-full shadow-md  bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 z-10"
                        title="Options"
                        onClick={() => handleParticipantCardOptionsClick(val)}
                      />
                    </div>
                    {controlledUserId === val && (
                      <div className=" absolute top-4 right-5 bg-white overflow-y-auto p-1 rounded-xl shadow-md w-[15vw] transition duration-500 z-10  " ref={instructorControlRef}>
                        {/* {agentScreenAccess && (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            key="Screen Share"
                            onClick={() => shareUnshareOpenentPeerScreen(val)}
                          >
                            <ViewfinderCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " />
                            <p className="md:lg:sm:text-xs xl:text-sm">
                              {remotePeer.peerScreenStopped
                                ? "Share Screen"
                                : "Unshare Screen"}
                            </p>
                          </div>
                        )} */}

                        {/* {(!remotePeer.peerScreenStopped ||
                          !remotePeer.peerscreenTwoStopped) &&
                          agentScreenAccess &&
                          viewMode !== roomViewModeConst.collabMode &&
                          !screenControl ? (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            key="Screen Share Two"
                            onClick={() =>
                              shareUnshareOpenentPeerScreenTwo(val)
                            }
                          >
                            <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " />
                            <p className="md:lg:sm:text-xs xl:text-sm">
                              {remotePeer.peerscreenTwoStopped
                                ? "Share Screen Two"
                                : "Unshare Screen Two"}
                            </p>
                          </div>
                        ) : null} */}

                        {isAudioAllowedInRoom && !isPrivateCallOn &&
                          !callModalOption.isCallModal &&
                          remotePeer.peerAudioPaused &&
                          !remotePeer.isCallModalOn &&
                          !remotePeer.isPrivateCallOn &&
                          remotePeer.peerType === role.AGENT &&
                          supervisorCanObserve ? (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            key="Observe"
                            onClick={() =>
                              handleStartAndStopObserveAgent(
                                remotePeer.peerUserId,
                                remotePeer.openentObserverAudioId
                              )
                            }
                          >
                            {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                            <p className="md:lg:sm:text-xs xl:text-sm">
                              {openentObserveeAudioId === remotePeer.peerUserId
                                ? "Stop Observing"
                                : openentObserveeAudioId !== "" &&
                                  openentObserveeAudioId !==
                                  remotePeer.peerUserId
                                  ? "Can't Observe"
                                  : remotePeer.openentObserverAudioId !== ""
                                    ? "Already Observed"
                                    : "Observe"}
                            </p>
                          </div>
                        ) : null}
                        {/* checking */}
                        {remotePeer.peerType === role.AGENT && (screenControl || remotePeers[val].enableDesktopApp) && !remotePeer.peerScreenStopped ? (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            key="screen control"
                            onClick={() =>
                              handleScreenControl(remotePeers[val])
                            }
                          >
                            {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                            <p className="md:lg:sm:text-xs xl:text-sm">
                              {"Screen control"}
                            </p>
                          </div>
                        ) : null}

                        {isAudioAllowedInRoom &&
                          ((!isPrivateCallOn && !callModalOption.isCallModal) ||
                            openentCallerUserId === remotePeer.peerUserId ? (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              key="Private Call"
                              onClick={() => handlePrivateCall(remotePeer)}
                            >
                              {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                              <p className="md:lg:sm:text-xs xl:text-sm">
                                {isPrivateCallOn &&
                                  remotePeer.isPrivateCallOn &&
                                  openentCallerUserId === remotePeer.peerUserId
                                  ? "Stop Private Call"
                                  : isPrivateCallOn &&
                                    !remotePeer.isPrivateCallOn
                                    ? "Your private call on"
                                    : remotePeer.isPrivateCallOn ||
                                      (!remotePeer.isPrivateCallOn &&
                                        remotePeer.isCallModalOn)
                                      ? "Already in Call"
                                      : "Private Call"}
                              </p>
                            </div>
                          ) : null)}

                        {constantMethod.checkHigherRole(loggedInUser.role) && roomViewModeConst.lectureMode === viewMode ? <div
                          className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                          onClick={() => promoteUser(remotePeer.peerUserId)}
                        >
                          <p
                            title={"Promote User"}
                            className="md:lg:sm:text-xs xl:text-sm"
                          >
                            Promote User
                          </p>
                        </div> : null}



                        {!constantMethod.checkHigherRole(
                          remotePeer.peerType
                        ) && !remotePeer.peerVideoPaused && (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              onClick={() =>
                                handleFeedbackClick(remotePeer.peerUserId)
                              }
                            >
                              {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                              <p className="md:lg:sm:text-xs xl:text-sm">
                                Feedback Image
                              </p>
                            </div>
                          )}

                        {constantMethod.checkHigherRole(loggedInUser.role) ? (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            onClick={() =>
                              handleRemoveParticipant(
                                remotePeer.peerUserId,
                                remotePeer.peerUsername,
                                remotePeer.peerName
                              )
                            }
                          >
                            <p
                              title={"Remove Participant"}
                              className="md:lg:sm:text-xs xl:text-sm"
                            >
                              Remove Participant
                            </p>
                          </div>
                        ) : null}

                        {constantMethod.checkHigherRole(loggedInUser.role) && supervisorCanRemoveHandraise &&
                          store.getState().chat.onlineUsers.some(
                            (user: any) =>
                              user.userId === remotePeer.peerUserId &&
                              user.emojiForIconPeer === emojiEventConst.HANDRAISE
                          ) ? (
                          <div
                            className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                            onClick={() =>
                              handleRemoveHandraise(
                                remotePeer.peerUserId,
                                remotePeer.peerUsername,
                                remotePeer.peerName
                              )
                            }
                          >
                            <p
                              title={"Remove Handraise"}
                              className="md:lg:sm:text-xs xl:text-sm"
                            >
                              Remove Handraise
                            </p>
                          </div>
                        ) : null}

                        {!constantMethod.checkHigherRole(
                          remotePeer.peerType
                        ) && ((!remotePeer.peerVideoPaused && !remotePeer.peerScreenStopped) || (!remotePeer.peerVideoPaused && !remotePeer.peerscreenTwoStopped) || (!remotePeer.peerscreenTwoStopped && !remotePeer.peerScreenStopped)) &&

                          (
                            <div
                              className="flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl gap-0 p-1 "
                              onClick={() =>
                                handleSwap(remotePeer.peerUserId)
                              }
                            >
                              {/* <StopCircleIcon className="w-6   text-gray-500 p-1 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " /> */}
                              <p className="md:lg:sm:text-xs xl:text-sm">
                                Swap Camera/Screen
                              </p>
                            </div>

                          )


                        }

                      </div>
                    )}

                    {streamInstance.peerRemoteStream &&
                      streamInstance.peerRemoteStream[val] ? (
                      <>
                        {/* if only screen1 is shared */}
                        {!remotePeer.peerScreenStopped &&
                          remotePeer.peerVideoPaused &&
                          remotePeer.peerscreenTwoStopped &&
                          streamInstance.peerRemoteStream[val].screenStream && (
                            <div
                              className={`h-full flex justify-center w-full bg-gray-100 rounded-xl`}
                            >
                              <Video
                                className="w-1/2"
                                src={
                                  streamInstance.peerRemoteStream[val]
                                    .screenStream
                                }
                                mediaType={mediaTypeConstant.screen}
                                userId={val}
                              />
                            </div>
                          )}

                        {/* if only screen 2 is shared  */}
                        {!remotePeer.peerscreenTwoStopped &&
                          remotePeer.peerVideoPaused &&
                          remotePeer.peerScreenStopped &&
                          streamInstance.peerRemoteStream[val]
                            .screenTwoStream && (
                            <div
                              className={`h-full flex justify-center w-full bg-gray-100 rounded-xl`}
                            >
                              <Video
                                className="w-1/2"
                                src={
                                  streamInstance.peerRemoteStream[val]
                                    .screenTwoStream
                                }
                                mediaType={mediaTypeConstant.screentwo}
                                userId={val}
                              />
                            </div>
                          )}

                        {/* if only video is shared  */}
                        {!remotePeer.peerVideoPaused &&
                          remotePeer.peerscreenTwoStopped &&
                          remotePeer.peerScreenStopped &&
                          streamInstance.peerRemoteStream[val].videoStream && (
                            <div
                              className={`h-full flex justify-center w-full bg-white rounded-xl`}
                            >
                              <Video
                                className="w-full object-cover"
                                src={
                                  streamInstance.peerRemoteStream[val]
                                    .videoStream
                                }
                                mediaType={mediaTypeConstant.video}
                                userId={val}
                              />
                            </div>
                          )}

                        {/* screen1 and screen2 shared  */}
                        {!remotePeer.peerscreenTwoStopped &&
                          !remotePeer.peerScreenStopped &&
                          remotePeer.peerVideoPaused &&
                          streamInstance.peerRemoteStream[val].screenStream && (
                            <div className="h-full flex justify-center w-full bg-gray-100 rounded-xl" id={remotePeer.peerUserId} ref={userIdRef} >
                              {!remotePeer.peerScreenStopped && (
                                <Video
                                  className="w-1/2"
                                  src={
                                    streamInstance.peerRemoteStream[val]
                                      .screenStream
                                  }
                                  mediaType={mediaTypeConstant.screen}
                                  userId={val}
                                />
                              )}

                              <div className=" h-1/4 w-1/4 absolute right-0 bottom-0 flex justify-end items-center gap-1">
                                {
                                  !remotePeer.peerscreenTwoStopped &&
                                  streamInstance.peerRemoteStream[val]
                                    .screenTwoStream && (
                                    <Video
                                      className="w-1/2  border-[3px] border-white rounded-xl shadow-2xl"
                                      src={
                                        streamInstance.peerRemoteStream[val]
                                          .screenTwoStream
                                      }
                                      mediaType={mediaTypeConstant.screentwo}
                                      userId={val}
                                    />
                                  )
                                  // </div><div className=" bg-white p-[.5] w-1/2 rounded-xl h-full shadow-xl">
                                }
                              </div>
                            </div>
                          )}

                        {/* screen2 and video  */}
                        {!remotePeer.peerscreenTwoStopped &&
                          !remotePeer.peerVideoPaused &&
                          remotePeer.peerScreenStopped &&
                          streamInstance.peerRemoteStream[val]
                            .screenTwoStream && (
                            <div className="h-full flex justify-center w-full bg-gray-100 rounded-xl" id={remotePeer.peerUserId} ref={userIdRef} >
                              {!remotePeer.peerscreenTwoStopped && (
                                <Video
                                  className="w-1/2 "
                                  src={
                                    streamInstance.peerRemoteStream[val]
                                      .screenTwoStream
                                  }
                                  mediaType={mediaTypeConstant.screentwo}
                                  userId={val}
                                />
                              )}

                              <div className=" h-1/4 w-1/4 absolute right-0 bottom-0 flex justify-end items-center gap-1">
                                {
                                  !remotePeer.peerVideoPaused &&
                                  streamInstance.peerRemoteStream[val]
                                    .videoStream && (
                                    <Video
                                      className="w-1/2 object-cover  border-[3px] bg-white border-white rounded-xl shadow-2xl"
                                      src={
                                        streamInstance.peerRemoteStream[val]
                                          .videoStream
                                      }
                                      mediaType={mediaTypeConstant.video}
                                      userId={val}
                                    />
                                  )
                                  // </div><div className=" bg-white p-[.5] w-1/2 rounded-xl h-full shadow-xl">
                                }
                              </div>
                            </div>
                          )}

                        {/* screen1 and video  */}
                        {!remotePeer.peerVideoPaused &&
                          !remotePeer.peerScreenStopped &&
                          remotePeer.peerscreenTwoStopped &&
                          streamInstance.peerRemoteStream[val].screenStream && (
                            <div className="h-full flex justify-center w-full bg-gray-100 rounded-xl" id={remotePeer.peerUserId} ref={userIdRef}>
                              {!remotePeer.peerScreenStopped && (
                                <Video
                                  className="w-1/2"
                                  src={
                                    streamInstance.peerRemoteStream[val]
                                      .screenStream
                                  }
                                  mediaType={mediaTypeConstant.screen}
                                  userId={val}
                                />
                              )}

                              <div className=" h-1/4 w-1/4 absolute right-0 bottom-0 flex justify-end items-center gap-1">
                                {
                                  !remotePeer.peerVideoPaused &&
                                  streamInstance.peerRemoteStream[val]
                                    .videoStream && (
                                    <Video
                                      className="w-1/2 object-cover  border-[3px] border-white bg-white rounded-xl shadow-2xl"
                                      src={
                                        streamInstance.peerRemoteStream[val]
                                          .videoStream
                                      }
                                      mediaType={mediaTypeConstant.video}
                                      userId={val}
                                    />
                                  )
                                  // </div><div className=" bg-white p-[.5] w-1/2 rounded-xl h-full shadow-xl">
                                }
                              </div>
                            </div>
                          )}

                        {/* Case for  both the screens and Video  */}
                        {!remotePeer.peerVideoPaused &&
                          !remotePeer.peerScreenStopped &&
                          !remotePeer.peerscreenTwoStopped &&
                          streamInstance.peerRemoteStream[val].screenStream && (
                            <div className="h-full flex justify-center w-full bg-gray-100 rounded-xl" id={remotePeer.peerUserId} ref={userIdRef}>
                              {!remotePeer.peerScreenStopped && (
                                <Video
                                  className="w-1/2 "
                                  src={
                                    streamInstance.peerRemoteStream[val]
                                      .screenStream
                                  }
                                  mediaType={mediaTypeConstant.screen}
                                  userId={val}
                                />
                              )}

                              <div className=" h-1/4 w-1/2 absolute right-0 bottom-0 flex justify-end items-center gap-1">
                                {
                                  !remotePeer.peerscreenTwoStopped &&
                                  streamInstance.peerRemoteStream[val]
                                    .screenTwoStream && (
                                    <Video
                                      className="w-1/2  border-[3px] border-white bg-gray-100 rounded-xl shadow-2xl"
                                      style={{ width: "50%" }}
                                      src={
                                        streamInstance.peerRemoteStream[val]
                                          .screenTwoStream
                                      }
                                      mediaType={mediaTypeConstant.screentwo}
                                      userId={val}
                                    />
                                  )
                                  // </div><div className=" bg-white p-[.5] w-1/2 rounded-xl h-full shadow-xl">
                                }
                                {
                                  !remotePeer.peerVideoPaused &&
                                  streamInstance.peerRemoteStream[val]
                                    .videoStream && (
                                    <Video
                                      className="w-1/2 object-cover  border-[3px] border-white bg-white rounded-xl shadow-2xl"
                                      style={{ width: "50%" }}
                                      src={
                                        streamInstance.peerRemoteStream[val]
                                          .videoStream
                                      }
                                      mediaType={mediaTypeConstant.video}
                                      userId={val}
                                    />
                                  )
                                  // </div><div className=" bg-white p-[.5] w-1/2 rounded-xl h-full shadow-xl">
                                }
                              </div>
                            </div>
                          )}
                      </>
                    ) : null}

                    {/* <div className={`${!val.screen1 ? 'h-full' : 'h-[47%]'} flex justify-between w-full gap-1`}>

                        {
                          val.screen2 &&
                          <div className={`${!val.screen1 && !val.video ? 'w-full h-full' : 'w-1/2 h-full'} bg-gray-100 rounded-xl`}>

                            <Video className="w-1/2" />

                          </div>
                        }


                        {
                          val.video &&
                          <div className={`${!val.screen1 && !val.screen2 ? 'w-full h-full' : 'w-1/2 h-full'} bg-gray-100 rounded-xl `}>


                            <Video className="w-1/2" /> */}

                    {/* {
                            showOptions &&
                            <div className=" bg-white overflow-y-auto absolute bottom-6 left-6 p-1 rounded-xl shadow-md w-[15vw] transition duration-500 z-10  ">
                                {
                                    options.map(option => (
                                        <div className=" flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl p-1 " key={option.title}>


                                            <option.Icon className="xl:w-11 lg:w-9   text-gray-500 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 " title="Present" />
                                            <p className="lg:text-sm xl:text-base">{option.title}</p>

                                        </div>
                                    ))
                                }



                            </div>
                        } */}
                    {/* </div>
                        }




                      </div> */}
                    <p className="lg:text-[.6rem] capitalize xl:text-[.9rem] p-[.5px] px-[2px]  absolute bottom-1 left-1 rounded-md  text-white bg-gray-500 backdrop-blur-sm opacity-70">
                      {remotePeer.peerName}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      <Transition appear show={isScreenControlModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden backdrop-blur-sm">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[70%] sm:max-w-[70%] max-h-[90vh] overflow-y-scroll scrollbar sm:p-6">
                  <ScreenControl closeScreenModal={closeScreenModal} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Feedback Images  */}
      <Transition appear show={isFeedbackModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden backdrop-blur-sm">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[50%] sm:max-w-[50%] max-h-[70vh] overflow-y-scroll scrollbar sm:p-6">
                  <p
                    className=" absolute right-0 top-2 text-md text-gray-900 cursor-pointer"
                    title="Close"
                    onClick={() => {
                      setIsFeedbackModalOpen(false);
                    }}
                  >
                    <XMarkIcon className=" h-5 w-5" />
                  </p>
                  <h1 className="font-base font-semibold text-center">
                    Choose your Issue!
                  </h1>
                  <span className="isolate inline-flex rounded-md shadow-sm mt-2">
                    <button
                      type="button"
                      className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm  text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      onClick={() =>
                        handleFeedbackSubmit(
                          "Mobile Phone Not Detected",
                          currentPeerUserId
                        )
                      }
                    >
                      Mobile Phone Not Detected
                    </button>
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm  text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      onClick={() =>
                        handleFeedbackSubmit(
                          "Multiple People Not Detected",
                          currentPeerUserId
                        )
                      }
                    >
                      Multiple People Not Detected
                    </button>
                    <button
                      type="button"
                      className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      onClick={() =>
                        handleFeedbackSubmit(
                          "Face Not Detected",
                          currentPeerUserId
                        )
                      }
                    >
                      Face Not Detected
                    </button>
                  </span>
                  <div className="relative mt-4">
                    <div
                      aria-hidden="true"
                      className="absolute inset-0 flex items-center"
                    >
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-white px-2 text-sm text-gray-500">
                        Or
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="mt-2">
                      <p className="text-gray-500 text-sm italic">
                        Add your Feedback
                      </p>
                      <textarea
                        name="comment"
                        value={customFeedbackValue}
                        onChange={(e) => setCustomFeedbackValue(e.target.value)}
                        rows={4}
                        className="w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                      <span className="isolate inline-flex rounded-md shadow-sm mt-2">
                        <button
                          type="button"
                          className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm  text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                          onClick={() => {
                            setIsFeedbackModalOpen(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                          onClick={() =>
                            handleCustomFeedbackCheck(currentPeerUserId)
                          }
                        >
                          Submit
                        </button>
                      </span>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
